import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; //Example style, you can use another
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { ExportJsonCsv } from "react-export-json-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  Folder,
} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import ErrorBoundary from "../../ErrorBoundary";
import {
  arraysAreEqual,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import AddEditEvent from "./AddEditEvent";

const EMAIL_EDITOR_TYPES = {
  Signup: "signupEmail",
  Approved: "approvedEmail",
  Denied: "deniedEmail",
};

const StatusEnum = Object.freeze({
  PENDING: "pending",
  APPROVED: "approved",
  DENIED: "denied",
  WAITLISTED: "waitlisted",
});

function reorderStatusEnum(
  order = ["APPROVED", "WAITLISTED", "DENIED", "PENDING"]
) {
  const reorderedEnum = {};
  for (const status of order) {
    if (status in StatusEnum) {
      reorderedEnum[status] = StatusEnum[status];
    }
  }
  return reorderedEnum;
}

const EmailStatusEnum = Object.freeze({
  SENT: "sent",
  NOT_SENT: "not_sent",
});

const FinalStatusEnum = Object.freeze({
  FINAL: "final",
  NOT_FINAL: "not_final",
});

// Define the possible values for the 'attendanceStatus' enum
const AttendanceStatusEnum = Object.freeze({
  ATTENDING: "attending",
  TENTATIVE: "tentative",
  NOT_ATTENDING: "not_attending",
});

const getAttendanceStatusDisplayText = (attendanceStatus) => {
  let badgeText = "";
  if (attendanceStatus === AttendanceStatusEnum.ATTENDING) {
    badgeText = "Yes";
  } else if (attendanceStatus === AttendanceStatusEnum.TENTATIVE) {
    badgeText = "Tentative";
  } else if (attendanceStatus === AttendanceStatusEnum.NOT_ATTENDING) {
    badgeText = "No";
  }
  return badgeText;
};

const getBadgeBgFromStatus = (status) => {
  let badgeBg = "light";
  if (status === StatusEnum.PENDING) {
    badgeBg = "light";
  } else if (status === StatusEnum.APPROVED) {
    badgeBg = "success";
  } else if (status === StatusEnum.DENIED) {
    badgeBg = "danger";
  } else if (status === StatusEnum.WAITLISTED) {
    badgeBg = "warning";
  }
  return badgeBg;
};

const getBadgeClassFromAttendanceStatus = (attendanceStatus) => {
  let badgeClass = "badge-outline-light";
  if (attendanceStatus === AttendanceStatusEnum.ATTENDING) {
    badgeClass = "badge-outline-success";
  } else if (attendanceStatus === AttendanceStatusEnum.TENTATIVE) {
    badgeClass = "badge-outline-warning";
  } else if (attendanceStatus === AttendanceStatusEnum.NOT_ATTENDING) {
    badgeClass = "badge-outline-danger";
  }
  return badgeClass;
};

const RsvpStatusUpdatePopup = ({
  attendanceMode = false,
  showPopup,
  setShowPopup,
  rsvp,
  optionRequest = {},
  notRequestedOptionId = null,
  position,
  handleUpdateRsvp,
  handleUpdateRsvpCreateOptionRequest,
}) => {
  const [final, setFinal] = useState(optionRequest?.final || false);
  const { attendanceStatus, attendanceStatusUpdates } = rsvp;
  const popupRef = useRef(null);

  // user hasn't requested this optionId but we are allowing the dashboard user to create a request on their behalf
  let notRequestedMode = false;
  if (notRequestedOptionId) {
    notRequestedMode = true;
  }

  useEffect(() => {
    if (showPopup && popupRef.current) {
      // Measure the width of the popup and calculate its left position to center it under the badge.
      const popupWidth = popupRef.current.offsetWidth;
      const popupX = position.x - popupWidth / 2;
      popupRef.current.style.left = `${popupX}px`;
      popupRef.current.style.transform = "scale(0)";

      setTimeout(() => {
        popupRef.current.style.transform = "scale(1)";
      }, 100);
    } else if (popupRef.current) {
      popupRef.current.style.transform = "scale(0)";
    }
  }, [showPopup]);

  const handleBadgeClick = (newStatus) => {
    if (notRequestedMode) {
      handleUpdateRsvpCreateOptionRequest(newStatus);
    } else {
      handleUpdateRsvp(newStatus, final);
    }
  };

  const handleFinalChange = (event) => {
    setFinal(event.target.checked);
    if (notRequestedMode) {
      // handleUpdateRsvpCreateOptionRequest(
      //   optionRequest.status,
      //   event.target.checked
      // );
    } else {
      handleUpdateRsvp(optionRequest.status, event.target.checked);
    }
  };

  // option updates
  const optionUpdateHistory = { status: null, final: null };
  if (!attendanceMode && optionRequest && optionRequest.statusUpdates) {
    // reverse list so most recent updates are at the start
    const statusUpdates = [...optionRequest.statusUpdates].reverse();
    // marked as final so find most recent final mark
    if (optionRequest.final) {
      let lastFinalUpdate = null;
      // look for a status request which has final: true and remember it, then look for a final: false, when a final: false is found, break
      for (let i = 0; i < statusUpdates.length; i++) {
        const update = statusUpdates[i];
        if (update.final) {
          lastFinalUpdate = update;
        } else if (!update.final) {
          break;
        }
      }
      optionUpdateHistory.final = lastFinalUpdate;
    }
    // get last status change
    let lastStatusUpdate = null;
    for (let i = 0; i < statusUpdates.length; i++) {
      const update = statusUpdates[i];
      if (update.status === optionRequest.status) {
        lastStatusUpdate = update;
      } else if (update.status !== optionRequest.status) {
        break;
      }
    }
    optionUpdateHistory.status = lastStatusUpdate;
  } else if (!attendanceMode && notRequestedMode) {
  }

  // attendance updates
  const attendanceUpdateHistory = { status: null };
  if (
    attendanceMode &&
    attendanceStatusUpdates &&
    attendanceStatusUpdates.length
  ) {
    // reverse list so most recent updates are at the start
    const statusUpdates = [...attendanceStatusUpdates].reverse();
    // get last status change
    let lastStatusUpdate = null;
    for (let i = 0; i < statusUpdates.length; i++) {
      const update = statusUpdates[i];
      if (update.attendanceStatus === attendanceStatus) {
        lastStatusUpdate = update;
      } else if (update.attendanceStatus !== attendanceStatus) {
        break;
      }
    }
    attendanceUpdateHistory.status = lastStatusUpdate;
  }

  const AttendanceUpdater = () => {
    return (
      <>
        <div className="badge-options">
          {Object.entries(AttendanceStatusEnum).map(([key, status]) => {
            if (status === attendanceStatus) {
              return null; // option already selected
            }
            console.log(`status`, status);
            return (
              <div className="badge-wrapper" key={key}>
                <Badge
                  bg={null}
                  className={`status-badge hvr-shrink ${getBadgeClassFromAttendanceStatus(
                    status
                  )}`}
                  onClick={() => handleBadgeClick(status)}
                >
                  {getAttendanceStatusDisplayText(status)}
                </Badge>
              </div>
            );
          })}
        </div>
        {attendanceUpdateHistory.status ? (
          <div className="option-update-history rmono">
            {attendanceUpdateHistory.status && (
              <>
                <div className="update-line">
                  <span className="">
                    {getAttendanceStatusDisplayText(
                      attendanceUpdateHistory.status.attendanceStatus
                    )}
                  </span>{" "}
                  by {attendanceUpdateHistory.status.employeeName}
                </div>
                <div className="update-line-time rmono">
                  {epochToDateTimeString(
                    attendanceUpdateHistory.status.updateTime
                  )}
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  };

  const OptionRequestUpdater = () => {
    return (
      <>
        {notRequestedMode ? (
          <div className="or-not-requested-title">
            Create a request for this event:
          </div>
        ) : null}
        {notRequestedMode ? null : (
          <div className="checkbox-wrapper d-flex justify-content-center align-items-center">
            <label className="d-flex justify-content-center align-items-center">
              <input
                type="checkbox"
                checked={final}
                onChange={handleFinalChange}
                style={{ marginRight: `4px` }}
              />
              Mark as Final
            </label>
          </div>
        )}
        <div className="badge-options">
          {Object.entries(StatusEnum).map(([key, status]) => {
            if (status === optionRequest?.status) {
              return null; // option already selected
            }
            return (
              <div className="badge-wrapper" key={key}>
                <Badge
                  bg={getBadgeBgFromStatus(status)}
                  className="status-badge hvr-shrink"
                  onClick={() => handleBadgeClick(status)}
                >
                  {toTitleCase(status)}
                </Badge>
              </div>
            );
          })}
        </div>
        {optionUpdateHistory.status || optionUpdateHistory.final ? (
          <div className="option-update-history rmono">
            {optionUpdateHistory.final && (
              <>
                <div className="update-line">
                  <span className="">Final</span> by{" "}
                  {optionUpdateHistory.final.employeeName}
                </div>
                <div className="update-line-time rmono">
                  {epochToDateTimeString(optionUpdateHistory.final.updateTime)}
                </div>
              </>
            )}
            {optionUpdateHistory.status && (
              <>
                <div className="update-line">
                  <span className="">
                    {toTitleCase(optionUpdateHistory.status.status)}
                  </span>{" "}
                  by {optionUpdateHistory.status.employeeName}
                </div>
                <div className="update-line-time rmono">
                  {epochToDateTimeString(optionUpdateHistory.status.updateTime)}
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div
        ref={popupRef}
        className="popup-container"
        style={{
          top: position.y + 10,
          left: position.x,
        }}
        onMouseLeave={() => {
          if (popupRef.current) {
            popupRef.current.style.transform = "scale(0)";
          }
          setTimeout(() => {
            setShowPopup(false);
          }, 150);
        }}
      >
        {attendanceMode && <AttendanceUpdater />}
        {!attendanceMode && <OptionRequestUpdater />}
      </div>
    </>
  );
};

const CommentPopup = ({ rsvp, onSubmitComment }) => {
  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [unsortedComments, setUnsortedComments] = useState(
    rsvp ? rsvp.comments : []
  );

  useEffect(() => {
    if (rsvp && rsvp.comments) {
      setUnsortedComments(rsvp.comments);
    }
  }, [rsvp]);

  function sortComments(comments) {
    if (!comments || !comments.length) {
      return [];
    }
    const orderedComments = comments
      .slice()
      .sort((a, b) => b.createdTime - a.createdTime);
    const rootComments = orderedComments.filter((c) => !c.isReply);
    const replyComments = orderedComments.filter((c) => c.isReply).reverse(); // reverse order so replies are shown cronologically
    const sortedRootComments = rootComments.map((c) => {
      // find replies to this comment and append them
      const replies = replyComments.filter(
        (r) => r.replyCommentId === c.commentId
      );
      c.replyComments = replies;
      return c;
    });
    const sorted = [];
    sortedRootComments.forEach((c) => {
      // push original comment
      sorted.push(c);
      if (c.replyComments && c.replyComments.length) {
        // push all replies
        c.replyComments.forEach((r) => sorted.push(r));
      }
    });
    return sorted;
  }
  // sort comments
  const comments = sortComments(unsortedComments);

  // const [comments, setComments] = useState([
  //   {
  //     commentId: "afghuifai",
  //     employeeId: "523251dd",
  //     employeeName: "Zeev Klein",
  //     content:
  //       "Test comment with any random content wish I had a lorem ipsum generator but als no I do not.",
  //     createdTime: 1693046478,
  //     isReply: false,
  //     replyCommentId: null,
  //     // seenBy: [
  //     //   {
  //     //     employeeId: String,
  //     //     employeeName: String,
  //     //     seenTime: Number,
  //     //   },
  //     // ],
  //   },
  //   ])

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleCreateComment = () => {
    if (commentText) {
      const success = onSubmitComment(
        rsvp.rsvpEventId,
        commentText,
        false,
        null
      );
      if (success) {
        setCommentText("");
      }
    }
  };

  const handleCancel = () => {
    // onClose();
  };

  const handleReply = (commentIndex) => {
    setShowReplyInput(commentIndex);
  };

  const handleCreateReply = (replyCommentId) => {
    if (replyText) {
      const success = onSubmitComment(
        rsvp.rsvpEventId,
        replyText,
        true,
        replyCommentId
      );
      if (success) {
        setReplyText("");
        setShowReplyInput(false);
      }
    }
  };

  const handleCancelReply = () => {
    setReplyText("");
    setShowReplyInput(false);
  };

  return (
    <div className="comments-popup">
      <div className="add-comment-container">
        <textarea
          placeholder="Write your comment..."
          value={commentText}
          onChange={handleCommentChange}
        />
        <div className="button-container">
          {/* <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button> */}
          <button className="create-button" onClick={handleCreateComment}>
            Comment
          </button>
        </div>
      </div>
      <div className="horizontal-bar"></div>
      <div className="comments-container">
        {comments.map((comment, index) => (
          <div
            className={`comment ${comment.isReply ? ` reply` : ``}`}
            key={index}
          >
            <div
              className="user-icon"
              style={{
                backgroundColor: stringToRGB(comment.employeeId),
              }}
            >
              <PersonIcon className="icon" />
            </div>
            <div className="comment-content">
              <div className="comment-header">
                <span className="commenter-name">{comment.employeeName}</span>
                <span className="comment-date">
                  {epochToDateTimeString(comment.createdTime)}
                </span>
              </div>
              <div className="comment-text">{comment.content}</div>
              <button
                className="reply-button"
                onClick={() => handleReply(index)}
              >
                Reply
              </button>
              {showReplyInput === index && (
                <div className="add-reply-container">
                  <textarea
                    placeholder="Write your reply..."
                    value={replyText}
                    onChange={handleReplyChange}
                  />
                  <div className="button-container">
                    <button
                      className="cancel-button"
                      onClick={handleCancelReply}
                    >
                      Cancel
                    </button>
                    <button
                      className="create-button"
                      onClick={() => handleCreateReply(comment.commentId)}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        {!comments.length ? (
          <div className="no-comments-text">No Comments Yet</div>
        ) : null}
      </div>
    </div>
  );
};

const ConfirmationEmailPopup = ({
  confirmationEmail,
  sendConfirmationEmail,
}) => {
  return (
    <div className="confirm-email-popup">
      <div className="email-container">
        <div className="sender">
          Sender:{" "}
          <span className="bold">{confirmationEmail.email.fromName}</span>
          <span>{` <${confirmationEmail.email.fromEmail}>`}</span>
        </div>
        <div className="sender">
          Recipient:{" "}
          <span className="bold">{confirmationEmail.email.toName}</span>
          <span>{` <${confirmationEmail.email.toEmail}>`}</span>
        </div>
        <div className="sender">
          Sent:{" "}
          <span className="bold">
            {epochToDateTimeString(confirmationEmail.sentTime)}
          </span>
        </div>
        <div className="viewer">
          <div className="subject">{confirmationEmail.email.subject}</div>
          <div className="divider"></div>
          <div className="body">
            {ReactHtmlParser(confirmationEmail.email.html)}
          </div>
        </div>
        <div
          className="resend-email"
          onClick={() => sendConfirmationEmail(confirmationEmail.rsvpEventId)}
        >
          Resend Email
        </div>
      </div>
    </div>
  );
};

const FilterInput = ({ filterKey, placeholder, handleSetFilter, filters }) => {
  const value = filters[filterKey] ? filters[filterKey] : "";
  return (
    <input
      onChange={(e) => handleSetFilter(filterKey, e.target.value)}
      type="text"
      className="form-control mt-1 filter-input"
      placeholder={placeholder}
      value={value}
    ></input>
  );
};

const FilterOption = ({
  filterKey,
  title,
  optionsEnum,
  handleSetFilter,
  filters,
  noTitleCase = false,
}) => {
  const value = filters[filterKey] ? filters[filterKey] : "All";
  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">
          <div className="option-title">{title}: </div>{" "}
        </span>
        <select
          defaultValue={"All"}
          className="form-control rmono"
          onChange={(e) => handleSetFilter(filterKey, e.target.value)}
          value={value}
        >
          <option>All</option>
          {Object.entries(optionsEnum).map(([key, status]) => {
            return (
              <option>{noTitleCase ? status : toTitleCase(status)}</option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const FilterCheckbox = ({ title, checked, handleChecked }) => {
  // title can be a string or HTML
  const isHTML = (str) => {
    // Simple check for HTML tags
    return /<\/?[a-z][\s\S]*>/i.test(str);
  };
  const renderTitle = () => {
    if (isHTML(title)) {
      return (
        <div
          className="option-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    }

    return <div className="option-title">{title}</div>;
  };

  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">{renderTitle()}</span>
        <div
          className="form-check custom-checkbox checkbox-success check-lg bs_exam_topper"
          // style={{ marginLeft: "4px" }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`FilterCheckbox_${title}`}
            required
            defaultChecked={false}
            checked={checked}
            onChange={(event) => handleChecked(event)}
          />
        </div>
      </div>
    </div>
  );
};

const ConfirmEmailTemplateEditor = ({
  event,
  fetchEvents,
  password,
  emailType,
}) => {
  const [options, setOptions] = useState(event ? event.options : []);
  const [emailSubject, setEmailSubject] = useState(
    event ? event[emailType].emailSubject : ""
  );
  const [emailBody, setEmailBody] = useState(
    event ? event[emailType].emailBody : ""
  );
  const [emailSendFrom, setEmailSendFrom] = useState(
    event ? event[emailType].emailSendFrom : ""
  );
  const [emailSendFromName, setEmailSendFromName] = useState(
    event ? event[emailType].emailSendFromName : ""
  );
  useEffect(() => {
    // new option created
    if (!event) {
      setOptions([]);
      setEmailSubject("");
      setEmailBody("");
      setEmailSendFrom("");
      setEmailSendFromName("");
    }
    // existing option obj
    else {
      setOptions(event.options);
      setEmailSubject(event[emailType].emailSubject);
      setEmailBody(event[emailType].emailBody);
      setEmailSendFrom(event[emailType].emailSendFrom);
      setEmailSendFromName(event[emailType].emailSendFromName);
    }
  }, [event]);

  console.log("event", event);
  console.log("event[emailType]", event[emailType]);

  let eventsHtml = ``;
  options.forEach((o) => {
    if (o.emailHtml) {
      eventsHtml += o.emailHtml;
    }
  });
  const emailHtml = `${emailBody
    .replace("{Events}", eventsHtml)
    .replace(`{FirstName|"There"}`, "There")}`;

  const handleSetOptions = (optionId, emailHtml) => {
    const newOptions = options.map((o) => {
      if (o.optionId === optionId) {
        o.emailHtml = emailHtml;
      }
      return o;
    });
    setOptions(newOptions);
  };

  async function editTemplateEmail() {
    try {
      const api = new ApiService({ password });
      const obj = {
        eventId: event.eventId,
        options,
      };
      obj[emailType] = {
        emailSubject,
        emailBody,
        emailSendFrom,
        emailSendFromName,
      };
      const eventsResponse = await api.post(
        "landmark/events/editEventEmailTemplate",
        obj
      );
      console.log(
        `editTemplateEmail() - landmark/events/editEventEmailTemplate - response`,
        eventsResponse
      );
      const { success, error } = eventsResponse;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        toast.success(success);
        // refresh events
        fetchEvents();
      }
    } catch (error) {
      console.error(error);
    }
  }

  let title = "";
  let description = "";
  if (emailType === EMAIL_EDITOR_TYPES.Approved) {
    title = "Confirmation Email Template Editor";
    description =
      "These emails are able to be sent when an RSVP has been approved for at least one event";
  } else if (emailType === EMAIL_EDITOR_TYPES.Denied) {
    title = "Denied Email Template Editor";
    description =
      "These emails are able to be sent when an RSVP has been denied for all requested events";
  } else if (emailType === EMAIL_EDITOR_TYPES.Signup) {
    title = "Signup Email Template Editor";
    description =
      "These emails are automatically sent after a user submits an invitation request";
  }

  return (
    <div className="confirm-email-editor-container">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
      <div className="row">
        <div className="col-6">
          <div className="subtitle">Edit Template</div>
          <div className="template-form">
            <div className="row">
              <div className="col-12">
                <button
                  className={`create-event-btn save-changes-btn btn btn-sm btn-primary`}
                  onClick={editTemplateEmail}
                >
                  {`Save Changes`}
                  <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
                </button>
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Send From Email{" "}
                  <span className="example-text">
                    (new emails needs to be configured first - contact
                    mjones@landmarkventures.com)
                  </span>
                </label>
                <input
                  type="text"
                  value={emailSendFrom}
                  className="form-control"
                  onChange={(e) => setEmailSendFrom(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Send From Name{" "}
                  {/* <span className="example-text">e.g. Andrew Kalaigian</span> */}
                </label>
                <input
                  type="text"
                  value={emailSendFromName}
                  className="form-control"
                  onChange={(e) => setEmailSendFromName(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Subject <span className="example-text"></span>
                </label>
                <input
                  type="text"
                  value={emailSubject}
                  className="form-control"
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Body <span className="example-text"></span>
                </label>
                {/* <textarea
                  value={emailBody}
                  className="form-control textarea rmono"
                  rows="20"
                  onChange={(e) => setEmailBody(e.target.value)}
                /> */}

                <ErrorBoundary>
                  <Editor
                    value={emailBody}
                    onValueChange={(code) => setEmailBody(code)}
                    highlight={(code) => {
                      // console.log("Code to highlight:", code);
                      try {
                        return highlight(code, languages.markup);
                      } catch (error) {
                        console.error("Highlighting error:", error);
                        return code; // Return unformatted code as a fallback
                      }
                    }}
                    padding={15}
                    className="code-editor"
                    style={{
                      color: "#000",
                      fontSize: 11,
                      backgroundColor: "#fff",
                      border: "1px #0072ff solid",
                      borderRadius: "0.625rem",
                      fontFamily:
                        "ui-monospace,Roboto Mono,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    }}
                  />
                </ErrorBoundary>

                {/* <CodeEditor
                  value={emailBody}
                  language="html"
                  onChange={(evn) => setEmailBody(evn.target.value)}
                  padding={15}
                  data-color-mode="light"
                  style={{
                    color: "#000",
                    fontSize: 11,
                    backgroundColor: "#fff",
                    border: "1px #0072ff solid",
                    borderRadius: "0.625rem",
                    fontFamily:
                      "ui-monospace,Roboto Mono,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                  }}
                /> */}
              </div>
              {/* show the options html only in the confirmation email (approved) editor */}
              {emailType === EMAIL_EDITOR_TYPES.Approved ? (
                <div className="form-group mb-3 col-12">
                  <label className="form-label title">
                    Events <span className="example-text"></span>
                  </label>
                  <br></br>
                  {!options || !options.length ? (
                    <div className="no-events-msg">
                      This event does not have any event options
                    </div>
                  ) : (
                    <>
                      {options
                        .sort((a, b) => a.startTime - b.startTime)
                        .map((o, i) => {
                          const marginTopClass = i === 0 ? "" : "mt-4";
                          return (
                            <>
                              <label className={`form-label ${marginTopClass}`}>
                                {o.title}
                              </label>
                              <ErrorBoundary>
                                <Editor
                                  value={
                                    options.find(
                                      (op) => op.optionId === o.optionId
                                    ).emailHtml || ""
                                  }
                                  onValueChange={(code) =>
                                    handleSetOptions(o.optionId, code)
                                  }
                                  highlight={(code) => {
                                    // console.log("Code to highlight:", code);
                                    try {
                                      return highlight(code, languages.markup);
                                    } catch (error) {
                                      console.error(
                                        "Highlighting error:",
                                        error
                                      );
                                      return code; // Return unformatted code as a fallback
                                    }
                                  }}
                                  padding={15}
                                  className="code-editor"
                                  style={{
                                    color: "#000",
                                    fontSize: 11,
                                    backgroundColor: "#fff",
                                    border: "1px #0072ff solid",
                                    borderRadius: "0.625rem",
                                    fontFamily:
                                      "ui-monospace,Roboto Mono,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                                  }}
                                />
                              </ErrorBoundary>

                              {/* <CodeEditor
                              value={
                                options.find((op) => op.optionId === o.optionId)
                                  .emailHtml
                              }
                              language="html"
                              onChange={(evn) =>
                                handleSetOptions(o.optionId, evn.target.value)
                              }
                              padding={15}
                              data-color-mode="light"
                              style={{
                                color: "#000",
                                fontSize: 11,
                                backgroundColor: "#fff",
                                border: "1px #0072ff solid",
                                borderRadius: "0.625rem",
                                fontFamily:
                                  "ui-monospace,Roboto Mono,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                              }}
                            /> */}
                            </>
                          );
                        })}
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="subtitle">Viewer</div>
          <div className="viewer">
            <div className="subject">{emailSubject}</div>
            <div className="divider"></div>
            <div className="body">{ReactHtmlParser(emailHtml)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MasterEventsTable = ({
  password,
  employee,
  selectedEvent,
  setSelectedEvent,
}) => {
  const [events, setEvents] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [rsvps, setRsvps] = useState([]);
  const [selectedRsvpIds, setSelectedRsvpIds] = useState([]);
  const [rsvpConfirmationEmails, setRsvpConfirmationEmails] = useState([]);
  const [rsvpDeniedEmails, setRsvpDeniedEmails] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [approvedAttendingFilter, setApprovedAttendingFilter] = useState(false);
  const [approvedFilter, setApprovedFilter] = useState(false);
  const [duplicatesOnlyFilter, setDuplicatesOnlyFilter] = useState(false);
  const [uniqueEmailsFilter, setUniqueEmailsFilter] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState(false);
  const [deletedOnlyFilter, setDeletedOnlyFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let bulkOnlyAffectOptionId = filters?.bulkRequestEvent || "All";
  if (bulkOnlyAffectOptionId === "All") {
    bulkOnlyAffectOptionId = false;
  } else if (bulkOnlyAffectOptionId) {
    // get optionId from title
    if (
      selectedEvent &&
      selectedEvent.options &&
      selectedEvent.options.length
    ) {
      const option = selectedEvent.options.find(
        (o) =>
          o.title.toLowerCase().trim() ===
          bulkOnlyAffectOptionId.toLowerCase().trim()
      );
      if (!option) {
        console.error(
          `bulkOnlyAffectOptionId: ERROR: could not retrieve optionId from title: ${bulkOnlyAffectOptionId}`
        );
      } else {
        bulkOnlyAffectOptionId = option.optionId;
      }
    }
  }
  console.log(`bulkOnlyAffectOptionId`, bulkOnlyAffectOptionId);

  const handleSetApprovedAttendingFilter = (event) => {
    const checked = event.target.checked;
    setApprovedAttendingFilter(checked);
  };

  const handleSetApprovedFilter = (event) => {
    const checked = event.target.checked;
    setApprovedFilter(checked);
  };

  const handleSetDuplicatesOnlyFilter = (event) => {
    const checked = event.target.checked;
    setDuplicatesOnlyFilter(checked);
  };

  const handleSetUniqueEmailsFilter = (event) => {
    const checked = event.target.checked;
    setUniqueEmailsFilter(checked);
  };

  const handleSetDeletedFilter = (event) => {
    const checked = event.target.checked;
    setDeletedFilter(checked);
  };

  const handleSetDeletedOnlyFilter = (event) => {
    const checked = event.target.checked;
    setDeletedOnlyFilter(checked);
  };

  const handleSetFiltersEnabled = (event) => {
    const checked = event.target.checked;
    setFiltersEnabled(checked);
  };

  const getFilteredRsvps = () => {
    function isString(s) {
      return typeof s === "string" || s instanceof String;
    }

    // filter just the rsvps for the selected event
    let filtered;
    try {
      if (selectedEvent && selectedEvent.eventId) {
        filtered = rsvps.filter((r) => r.eventId === selectedEvent.eventId);
      } else {
        throw "No selected event";
      }
    } catch (error) {
      console.log(`getFilteredRsvps() - error`, error);
      filtered = rsvps;
    }

    // deleted filter
    if (deletedFilter) {
      // 'Show Deleted' is checked - don't filter them out
    } else {
      // remove deleted rsvps
      filtered = filtered.filter((rsvp) => !rsvp.deleted);
    }

    // bypassed
    if (!filtersEnabled) {
      return filtered;
    }

    for (const filterKey in filters) {
      const filterValue = filters[filterKey];
      // attendanceStatus filtering
      if (filterKey === "attendanceStatus") {
        if (filterValue === "All") {
          // no nothing (allow all)
        } else {
          filtered = filtered.filter(
            (rsvp) =>
              rsvp.attendanceStatus.toLowerCase() === filterValue.toLowerCase()
          );
        }
      }
      // all requested events status filter
      else if (filterKey === "allRequested") {
        if (filterValue === "All") {
          // no nothing (allow all)
        } else {
          filtered = filtered.filter((rsvp) => {
            const allRequestsHaveStatus = rsvp.optionRequests.every(
              (or) => or.status.toLowerCase() === filterValue.toLowerCase()
            );
            return allRequestsHaveStatus;
          });
        }
      }
      // confirmation email sent filter
      else if (filterKey === "final") {
        if (filterValue === "All") {
          // no nothing (allow all)
        } else if (filterValue.toLowerCase() === FinalStatusEnum.FINAL) {
          filtered = filtered.filter((rsvp) => {
            const allFinal = rsvp.optionRequests.every((or) => or.final); // all option requests are marked as final
            return allFinal;
          });
        } else if (filterValue.toLowerCase() === FinalStatusEnum.NOT_FINAL) {
          filtered = filtered.filter((rsvp) => {
            const allFinal = rsvp.optionRequests.every((or) => or.final); // all option requests are marked as final
            return !allFinal;
          });
        }
      }
      // confirmation email sent filter
      else if (filterKey === "confirmationEmail") {
        console.log(`filterKey`, filterKey);
        console.log(`filterValue`, filterValue);
        if (filterValue === "All") {
          // no nothing (allow all)
        } else if (filterValue.toLowerCase() === EmailStatusEnum.SENT) {
          filtered = filtered.filter((rsvp) => {
            const existingConfirmEmail = rsvpConfirmationEmails.find(
              (e) => e.rsvpEventId === rsvp.rsvpEventId
            );
            return existingConfirmEmail;
          });
        } else if (filterValue.toLowerCase() === EmailStatusEnum.NOT_SENT) {
          filtered = filtered.filter((rsvp) => {
            const existingConfirmEmail = rsvpConfirmationEmails.find(
              (e) => e.rsvpEventId === rsvp.rsvpEventId
            );
            return !existingConfirmEmail;
          });
        }
      }
      // denied email sent filter
      else if (filterKey === "deniedEmail") {
        if (filterValue === "All") {
          // no nothing (allow all)
        } else if (filterValue.toLowerCase() === EmailStatusEnum.SENT) {
          filtered = filtered.filter((rsvp) => {
            const existingDeniedEmail = rsvpDeniedEmails.find(
              (e) => e.rsvpEventId === rsvp.rsvpEventId
            );
            return existingDeniedEmail;
          });
        } else if (filterValue.toLowerCase() === EmailStatusEnum.NOT_SENT) {
          filtered = filtered.filter((rsvp) => {
            const existingDeniedEmail = rsvpDeniedEmails.find(
              (e) => e.rsvpEventId === rsvp.rsvpEventId
            );
            return !existingDeniedEmail;
          });
        }
      }
      // bulk request affect only event filter
      else if (filterKey === "bulkRequestEvent") {
        // no need to do anything here - it's just so we don't trigger the else
        if (filterValue === "All") {
          // no nothing (allow all)
        } else {
          //const eventTitle = filterValue.toLowerCase().trim()
        }
      }
      // optionId filtering
      else if (
        selectedEvent &&
        selectedEvent.options.some((o) => o.optionId === filterKey)
      ) {
        // an optionId is being filtered for
        if (filterValue === "All") {
          // no nothing (allow all)
        } else {
          filtered = filtered.filter((rsvp) => {
            try {
              const or = rsvp.optionRequests.find(
                (or) => or.optionId === filterKey
              );
              if (or && or.status.toLowerCase() === filterValue.toLowerCase()) {
                return true;
              }
              return false;
            } catch (error) {
              return false;
            }
          });
        }
      }
      // string filtering
      else if (isString(filterValue) && filterValue.length > 0) {
        filtered = filtered.filter(
          (rsvp) =>
            rsvp[filterKey] &&
            rsvp[filterKey].toLowerCase().includes(filterValue.toLowerCase())
        );
      }
    }

    // approved filter
    if (approvedFilter) {
      filtered = filtered.filter((rsvp) => {
        const approved = rsvp.optionRequests.some(
          (or) => or.status === StatusEnum.APPROVED
        ); // has at least one approved request
        return approved;
      });
    }

    // approved and attending filter
    if (approvedAttendingFilter) {
      filtered = filtered.filter((rsvp) => {
        const attending =
          rsvp.attendanceStatus === AttendanceStatusEnum.ATTENDING ||
          rsvp.attendanceStatus === AttendanceStatusEnum.TENTATIVE;
        const approved = rsvp.optionRequests.some(
          (or) => or.status === StatusEnum.APPROVED
        ); // has at least one approved request
        return attending && approved;
      });
    }

    // duplicates only filter
    if (duplicatesOnlyFilter) {
      const emailCount = filtered.reduce((acc, rsvp) => {
        const email = rsvp.email.toLowerCase();
        acc[email] = (acc[email] || 0) + 1;
        return acc;
      }, {});

      filtered = filtered.filter((rsvp) => {
        return emailCount[rsvp.email.toLowerCase()] > 1;
      });
    }

    // unique emails filter
    if (uniqueEmailsFilter) {
      const latestRsvps = filtered.reduce((acc, rsvp) => {
        const email = rsvp.email.toLowerCase();
        // If the email doesn't exist in acc or if the current RSVP is more recent, update acc
        if (
          !acc[email] ||
          new Date(rsvp.createdTime) > new Date(acc[email].createdTime)
        ) {
          acc[email] = rsvp;
        }
        return acc;
      }, {});

      // Convert the result object back to an array
      filtered = Object.values(latestRsvps);
    }

    // show deleted filter and only deleted filter are both checked
    if (deletedFilter && deletedOnlyFilter) {
      filtered = filtered.filter((rsvp) => rsvp.deleted);
    }

    return filtered;
  };
  let filteredRsvps = getFilteredRsvps();

  // OptionRequest popup
  const [showPopupOR, setShowPopupOR] = useState(false);
  const [showPopupAttendance, setShowPopupAttendance] = useState(false);
  const [popupPositionOR, setPopupPositionOR] = useState({ x: 0, y: 0 });
  const [popupPositionAttendance, setPopupPositionAttendance] = useState({
    x: 0,
    y: 0,
  });
  const [selectedRsvpEvent, setSelectedRsvpEvent] = useState(null);
  const [selectedOptionRequest, setSelectedOptionRequest] = useState(null);
  const [notRequestedOptionId, setNotRequestedOptionId] = useState(null);
  const [showSignupEmailEditor, setShowSignupEmailEditor] = useState(false);
  const [showApprovedEmailEditor, setShowApprovedEmailEditor] = useState(false);
  const [showDeniedEmailEditor, setShowDeniedEmailEditor] = useState(false);

  function setShowEmailEditor(type, show) {
    setShowSignupEmailEditor(false);
    setShowApprovedEmailEditor(false);
    setShowDeniedEmailEditor(false);

    if (!show) {
      return;
    }

    if (type === EMAIL_EDITOR_TYPES.Approved) {
      setShowApprovedEmailEditor(true);
    } else if (type === EMAIL_EDITOR_TYPES.Denied) {
      setShowDeniedEmailEditor(true);
    } else if (type === EMAIL_EDITOR_TYPES.Signup) {
      setShowSignupEmailEditor(true);
    }
  }

  async function fetchRsvps() {
    const api = new ApiService({ password });
    try {
      const tag = "global";
      const response = await api.get(`landmark/events/getEventRsvps/${tag}`);
      console.log(`fetchRsvps()`, response);
      setRsvps(response);
      console.log("rsvps", response);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`events`, eventsResponse);
      setEvents(eventsResponse);

      // refresh selectedEvent (so it displays latest data)
      const _selectedEventId =
        selectedEvent && selectedEvent.eventId ? selectedEvent.eventId : null;
      if (_selectedEventId) {
        const newSelectedEvent = eventsResponse.find(
          (e) => e.eventId === _selectedEventId
        );
        if (newSelectedEvent) {
          setSelectedEvent(newSelectedEvent);
        }
      }
      //setSelectedEventId(eventsResponse[0].eventId);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchConfirmEmails() {
    try {
      if (!selectedEvent || !selectedEvent.eventId) {
        console.log(`fetchConfirmEmails() - invalid selected event`);
        return;
      }
      const api = new ApiService({ password });
      const eventId = selectedEvent.eventId;
      const resp = await api.get(
        `landmark/events/getRsvpConfirmationEmails/${eventId}`
      );
      console.log(`fetchConfirmEmails() - rsvpConfirmationEmails`, resp);
      setRsvpConfirmationEmails(resp);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchDeniedEmails() {
    try {
      if (!selectedEvent || !selectedEvent.eventId) {
        console.log(`fetchDeniedEmails() - invalid selected event`);
        return;
      }
      const api = new ApiService({ password });
      const eventId = selectedEvent.eventId;
      const resp = await api.get(
        `landmark/events/getRsvpDeniedEmails/${eventId}`
      );
      console.log(`fetchDeniedEmails() - rsvpDeniedEmails`, resp);
      setRsvpDeniedEmails(resp);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRsvps();
    fetchEvents();
  }, []);

  useEffect(() => {
    setSelectedEventId(selectedEvent.eventId);
    console.log("selectedEvent", selectedEvent);
    fetchConfirmEmails();
    fetchDeniedEmails();
  }, [selectedEvent]);

  const handleBadgeClickOR = (
    event,
    rsvp,
    optionRequest,
    notRequestedOptionId = null
  ) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionOR({ x: popupX, y: popupY });
    setSelectedRsvpEvent(rsvp);
    setSelectedOptionRequest(optionRequest);
    setNotRequestedOptionId(notRequestedOptionId);
    setShowPopupOR(true);
    setShowPopupAttendance(false);
  };

  const handleBadgeClickAttendance = (event, rsvp) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionAttendance({ x: popupX, y: popupY });
    setSelectedRsvpEvent(rsvp);
    setShowPopupOR(false);
    setShowPopupAttendance(true);
  };

  // allEvents.forEach(element => {
  //   columns.push({
  //     header: element.eventTitle,
  //     accessorKey: outputdata[0].
  //   })
  // });
  // const handleExportRows = (rows) => {
  //   csvExporter.generateCsv(rows.map((row) => row.original));
  // };

  // const handleExportData = () => {
  //   csvExporter.generateCsv(rsvps);
  // };

  // const handleMarkRsvpsAsHandled = async (rows) => {
  //   const eventRsvpIds = rows.map((row) => row.original.rsvpEventId);
  //   // mark all as handled
  //   const handledArr = eventRsvpIds.map((e) => true);
  //   try {
  //     const api = new ApiService({ password });
  //     const { success, error } = await api.post(
  //       `landmark/events/setEventRsvpsHandled/`,
  //       {
  //         eventRsvpIds,
  //         handledArr,
  //       }
  //     );
  //     if (!success) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else {
  //       toast.success(`${success}`);
  //       // refresh table
  //       RenderTable();
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  // const deleteRsvps = async (rows) => {
  //   const eventRsvpIds = rows.map((row) => row.original.rsvpEventId);
  //   try {
  //     const api = new ApiService({ password });
  //     const { success, error } = await api.post(
  //       `landmark/events/removeEventRsvps`,
  //       {
  //         eventRsvpIds,
  //       }
  //     );
  //     if (!success) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else {
  //       toast.success(`${success}`);
  //       // refresh table
  //       RenderTable();
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  // const csvOptions = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   useBom: true,
  //   useKeysAsHeaders: false,
  //   headers: columns.map((c) => c.header),
  // };

  // const csvExporter = new ExportToCsv(csvOptions);

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  let canNextPage = false;
  let canPreviousPage = false;
  const pageCount = Math.ceil(filteredRsvps.length / rowsPerPage);
  console.log(`pageIndex`, pageIndex);
  console.log(`pageCount`, pageCount);
  if (pageCount === 0 && pageIndex !== 0) {
    console.log(`resetting pageIndex to 0 (out of bounds)`);
    setPageIndex(0);
  } else if (pageCount > 0 && pageIndex + 1 > pageCount) {
    console.log(`resetting pageIndex to 0 (out of bounds)`);
    setPageIndex(0);
  }
  if (pageIndex + 1 < pageCount) {
    canNextPage = true;
  }
  if (pageIndex > 0) {
    canPreviousPage = true;
  }

  const goToPage = (pageNo) => {
    if (pageNo >= 0 && pageNo < pageCount) {
      setPageIndex(pageNo);
    } else {
    }
  };
  const nextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
    }
  };
  const previousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
    }
  };
  // Calculate the start index and end index for the current page
  const startIndex = pageIndex * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // get rsvps to display on the current page
  const pageRsvps = filteredRsvps.slice(startIndex, endIndex);

  if (!filteredRsvps || !selectedEventId || !events) {
    return <>No RSVPs or events or selectedEvent</>;
  }

  // get selected event obj
  const event = events.find((e) => e.eventId === selectedEventId);

  const handleSubmitRsvpComment = async (
    rsvpEventId,
    content,
    isReply,
    replyCommentId
  ) => {
    try {
      if (!employee) {
        toast.error(`An error occurred: employee not logged in`);
        return;
      }
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/submitEventRsvpComment`,
        {
          rsvpEventId,
          employee,
          content,
          isReply,
          replyCommentId,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh rsvps
        fetchRsvps();
        return true;
      } else if (info) {
        toast(`${info}`);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleUpdateRsvpOptionsRequest = async (status, final) => {
    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        rsvp: newRsvp,
      } = await api.post(`landmark/events/updateEventRsvpOptionRequest`, {
        rsvpEventId: selectedRsvpEvent.rsvpEventId,
        optionRequestId: selectedOptionRequest.optionRequestId,
        status,
        final,
      });
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update the rsvp
        const updatedRsvps = rsvps.map((r) =>
          r.rsvpEventId === newRsvp.rsvpEventId ? newRsvp : r
        );
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleUpdateRsvpCreateOptionRequest = async (status) => {
    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        optionRequest,
        rsvp: newRsvp,
      } = await api.post(`landmark/events/createEventRsvpOptionRequest`, {
        rsvpEventId: selectedRsvpEvent.rsvpEventId,
        optionId: notRequestedOptionId,
        status,
      });
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        setNotRequestedOptionId(null);
        setSelectedOptionRequest(optionRequest);
        // update the rsvp
        const updatedRsvps = rsvps.map((r) =>
          r.rsvpEventId === newRsvp.rsvpEventId ? newRsvp : r
        );
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleUpdateRsvpAttendance = async (attendanceStatus) => {
    try {
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/updateEventRsvpAttendanceStatus`,
        {
          rsvpEventId: selectedRsvpEvent.rsvpEventId,
          attendanceStatus,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh rsvps
        fetchRsvps();
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendConfirmationEmail = async (rsvpEventId) => {
    try {
      if (!employee) {
        toast.error(`An error occurred: employee not logged in`);
        return;
      }
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/sendRsvpConfirmationEmail`,
        {
          rsvpEventId,
          employee,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh confirm emails
        fetchConfirmEmails();
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendDeniedEmail = async (rsvpEventId) => {
    try {
      if (!employee) {
        toast.error(`An error occurred: employee not logged in`);
        return;
      }
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/sendRsvpDeniedEmail`,
        {
          rsvpEventId,
          employee,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh denied emails
        fetchDeniedEmails();
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const selectRsvps = (rsvpsToSelect) => {
    const newSelectedRsvpIds = [...selectedRsvpIds];
    rsvpsToSelect.forEach((rsvp) => {
      if (!newSelectedRsvpIds.includes(rsvp.rsvpEventId)) {
        newSelectedRsvpIds.push(rsvp.rsvpEventId);
      }
    });
    setSelectedRsvpIds(newSelectedRsvpIds);
  };

  const unselectRsvps = (rsvpsToUnselect) => {
    const rsvpIdsToUnselect = rsvpsToUnselect.map((r) => r.rsvpEventId);
    setSelectedRsvpIds((prevSelectedRsvpIds) => {
      return prevSelectedRsvpIds.filter((r) => !rsvpIdsToUnselect.includes(r));
    });
  };

  const toggleSelectAllPageRsvps = (event) => {
    console.log(`toggleSelectAllPageRsvps() - event.target`, event.target);
    const checked = event.target.checked;
    // select all rsvps on this page
    if (checked) {
      selectRsvps(pageRsvps);
    }
    // unselect all rsvps on this page
    else {
      unselectRsvps(pageRsvps);
    }
  };
  const toggleSelectRsvp = (event, rsvp) => {
    const checked = event.target.checked;
    console.log(`toggleSelectRsvp() - checked`, checked);
    if (checked) {
      selectRsvps([rsvp]);
    } else {
      unselectRsvps([rsvp]);
    }
  };

  if (!selectedEvent) {
    return null;
  }
  console.log(`selectedEvent`, selectedEvent);

  const handleSetFilter = (filterKey, value) => {
    const newFilters = { ...filters };
    newFilters[filterKey] = value;
    setFilters(newFilters);
  };

  let headers = [];
  // Assuming rsvps is not an empty array
  if (rsvps.length > 0) {
    const headersSet = new Set();
    rsvps.forEach((r) => {
      Object.keys(r).forEach((key) => {
        headersSet.add(key);
      });
    });

    headers = Array.from(headersSet).map((h) => {
      return { key: h, name: h };
    });
    // add option headers
    if (selectedEvent && selectedEvent.options) {
      selectedEvent.options
        .sort((a, b) => a.startTime - b.startTime)
        .forEach((option) => {
          const { title, optionId } = option;
          headers.push({ key: optionId, name: title });
        });
    }
    // remove unwanted fields
    const unwanted = [
      "attendanceStatusUpdates",
      "comments",
      "confirmEmails",
      "optionRequests",
      "__v",
      "_id",
      "partnerResponses",
    ];
    headers = headers.filter((h) => !unwanted.includes(h.key));
    console.log(`headers`, headers);
  }

  const prepRsvpsForCsvExport = () => {
    return rsvps
      .filter((r) => r.eventId === selectedEvent.eventId)
      .map((r) => {
        const rsvp = JSON.parse(JSON.stringify(r));
        rsvp.optionRequests.forEach((or) => {
          const { optionId, status } = or;
          rsvp[optionId] = status;
        });
        rsvp.createdTime = epochToDateTimeString(rsvp.createdTime);
        // selectedEvent.options.forEach((option) => {
        //   const { optionId } = option;
        //   if (!rsvp[optionId]) {
        //     rsvp[optionId] = "not requested";
        //   }
        // });
        // delete rsvp.attendanceStatusUpdates;
        // delete rsvp.comments;
        // delete rsvp.confirmEmails;
        // delete rsvp.optionRequests;
        // delete rsvp.__v;
        // delete rsvp._id;
        return rsvp;
      });
  };

  const nonPendingStatusesExistForSelectedRsvpsBulkOnlyAffectOptionId = () => {
    if (!bulkOnlyAffectOptionId) {
      // not filtering by optionId so don't need to check
      return;
    } else {
      const selectedRsvps = rsvps.filter((r) =>
        selectedRsvpIds.includes(r.rsvpEventId)
      );
      console.log(
        `checkForNonPendingStatusesBulkOnlyAffectOptionId() - selectedRsvps.length`,
        selectedRsvps.length
      );
      for (let i = 0; i < selectedRsvps.length; i++) {
        const rsvp = selectedRsvps[i];
        if (rsvp.optionRequests) {
          const relevantOrs = rsvp.optionRequests.filter(
            (or) => or.optionId === bulkOnlyAffectOptionId
          );
          if (relevantOrs.some((or) => or.status.toLowerCase() !== "pending")) {
            // non-pending status found - show warning
            return true;
          }
        }
      }
    }
    return false;
  };

  const setSelectedRsvpsAllOptionRequestsStatuses = async (status) => {
    if (nonPendingStatusesExistForSelectedRsvpsBulkOnlyAffectOptionId()) {
      window.alert(
        "Some of the affected requests are already Approved/Waitlisted/Denied. This is a safeguard to prevent accidentally overwriting those requests. Please use the filters to filter the event to just Pending requests only. No action has been taken. (If this is annoying you then tell mjones and he will remove this safeguard)"
      );
      return;
    }

    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        rsvps: newRsvps,
      } = await api.post(
        `landmark/events/setStatusAllEventRsvpOptionRequestBatch`,
        {
          rsvpEventIds: selectedRsvpIds,
          status,
          bulkOnlyAffectOptionId,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update the rsvps
        const updatedRsvps = rsvps.map((rsvp) => {
          // find if there's a corresponding newRsvp with the same rsvpEventId
          const matchingNewRsvp = newRsvps.find(
            (newRsvp) => newRsvp.rsvpEventId === rsvp.rsvpEventId
          );
          // if there's a matching newRsvp, replace the old one, otherwise keep the old one
          return matchingNewRsvp ? matchingNewRsvp : rsvp;
        });
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const setSelectedRsvpsAllOptionRequestsAsFinal = async (final = true) => {
    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        rsvps: newRsvps,
      } = await api.post(
        `landmark/events/setFinalAllEventRsvpOptionRequestBatch`,
        {
          rsvpEventIds: selectedRsvpIds,
          final,
          bulkOnlyAffectOptionId,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update the rsvps
        const updatedRsvps = rsvps.map((rsvp) => {
          // find if there's a corresponding newRsvp with the same rsvpEventId
          const matchingNewRsvp = newRsvps.find(
            (newRsvp) => newRsvp.rsvpEventId === rsvp.rsvpEventId
          );
          // if there's a matching newRsvp, replace the old one, otherwise keep the old one
          return matchingNewRsvp ? matchingNewRsvp : rsvp;
        });
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteSelectedRsvps = async () => {
    // Check if any RSVPs are selected
    if (selectedRsvpIds.length === 0) {
      toast.error("No RSVPs selected for deletion");
      return;
    }

    // Show confirmation prompt
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedRsvpIds.length} RSVP${
        selectedRsvpIds.length === 1 ? "" : "s"
      }?`
    );

    if (!confirmDelete) {
      // User clicked "Cancel"
      return;
    }

    try {
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/deleteRsvps`,
        {
          rsvpEventIds: selectedRsvpIds,
          deleted: true,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // Refresh RSVPs
        fetchRsvps();
        setSelectedRsvpIds([]); // clear selected since we deleted them all
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const undeleteSelectedRsvps = async () => {
    // Check if any RSVPs are selected
    if (selectedRsvpIds.length === 0) {
      toast.error("No RSVPs selected for restoration");
      return;
    }

    try {
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/deleteRsvps`,
        {
          rsvpEventIds: selectedRsvpIds,
          deleted: false,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // Refresh RSVPs
        fetchRsvps();
        // setSelectedRsvpIds([]); // clear selected since we deleted them all
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const csvAdjustedRsvps = prepRsvpsForCsvExport();
  const filteredCsvAdjustedRsvps = csvAdjustedRsvps.filter((r) =>
    filteredRsvps.some((fr) => fr.rsvpEventId === r.rsvpEventId)
  );
  const selectedCsvAdjustedRsvps = csvAdjustedRsvps.filter((r) =>
    selectedRsvpIds.some((id) => id === r.rsvpEventId)
  );
  const csvAdjustedRsvpsNoDeleted = csvAdjustedRsvps.filter((r) => !r.deleted);

  // // for approved batches
  // Step 1: Filter RSVPs that have at least one approved and final option request
  // const csvRsvpsAllApprovedFinal = csvAdjustedRsvpsNoDeleted.filter((rsvp) => {
  //   const approved = rsvp.optionRequests.some(
  //     (or) => or.status === StatusEnum.APPROVED && or.final
  //   );
  //   return approved;
  // });

  const emailEditorIsOpen =
    showApprovedEmailEditor || showDeniedEmailEditor || showSignupEmailEditor;

  const selectedRsvpsIncludeDeleted =
    selectedRsvpIds &&
    selectedRsvpIds.length &&
    rsvps
      .filter((r) => r.deleted)
      .some((r) => selectedRsvpIds.includes(r.rsvpEventId));

  return (
    <>
      {rsvps && rsvps.length ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="event-image-img">
              <img src={selectedEvent.imageUrl} className="img-fluid" />
            </div>
            <div className="row">
              <div className="outputHeader">{selectedEvent.title} RSVPs</div>
              <div className="events-owners-subheading">
                Browse and export RSVPs received for {selectedEvent.title}
                {/* Recommended workflow:
                <ul>
                <li>• Filter the data based on event (or company, etc.)</li>
                <li>
                • Click 'Export Current' to export the filtered data to a
                spreadsheet
                </li>
                <li>• Once handled, click 'Mark Current as Handled'</li>
              </ul> */}
              </div>
              <div className="col-12">
                <div className="add-edit-btn-container">
                  {!emailEditorIsOpen || showSignupEmailEditor ? (
                    <button
                      className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                      onClick={() =>
                        setShowEmailEditor(
                          EMAIL_EDITOR_TYPES.Signup,
                          !showSignupEmailEditor
                        )
                      }
                    >
                      {showSignupEmailEditor
                        ? `Hide Editor`
                        : `Edit Signup Email Template`}
                      <i className="event-owner-add fa-sharp fa-solid fa-envelope ml-05"></i>
                    </button>
                  ) : null}
                  {!emailEditorIsOpen || showApprovedEmailEditor ? (
                    <button
                      className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                      onClick={() =>
                        setShowEmailEditor(
                          EMAIL_EDITOR_TYPES.Approved,
                          !showApprovedEmailEditor
                        )
                      }
                    >
                      {showApprovedEmailEditor
                        ? `Hide Editor`
                        : `Edit Confirmation Email Template`}
                      <i className="event-owner-add fa-sharp fa-solid fa-envelope ml-05"></i>
                    </button>
                  ) : null}
                  {!emailEditorIsOpen || showDeniedEmailEditor ? (
                    <button
                      className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                      onClick={() =>
                        setShowEmailEditor(
                          EMAIL_EDITOR_TYPES.Denied,
                          !showDeniedEmailEditor
                        )
                      }
                    >
                      {showDeniedEmailEditor
                        ? `Hide Editor`
                        : `Edit Denied Email Template`}
                      <i className="event-owner-add fa-sharp fa-solid fa-envelope ml-05"></i>
                    </button>
                  ) : null}
                </div>
                {showApprovedEmailEditor && (
                  <div className="table-wrapper">
                    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                      <ConfirmEmailTemplateEditor
                        password={password}
                        event={selectedEvent}
                        fetchEvents={fetchEvents}
                        emailType={EMAIL_EDITOR_TYPES.Approved}
                      />
                    </motion.div>
                  </div>
                )}
                {showDeniedEmailEditor && (
                  <div className="table-wrapper">
                    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                      <ConfirmEmailTemplateEditor
                        password={password}
                        event={selectedEvent}
                        fetchEvents={fetchEvents}
                        emailType={EMAIL_EDITOR_TYPES.Denied}
                      />
                    </motion.div>
                  </div>
                )}
                {showSignupEmailEditor && (
                  <div className="table-wrapper">
                    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                      <ConfirmEmailTemplateEditor
                        password={password}
                        event={selectedEvent}
                        fetchEvents={fetchEvents}
                        emailType={EMAIL_EDITOR_TYPES.Signup}
                      />
                    </motion.div>
                  </div>
                )}
              </div>
              <>
                <></>
                <div className="outputBody">
                  <div className="table-wrapper">
                    {showPopupOR && (
                      <RsvpStatusUpdatePopup
                        showPopup={showPopupOR}
                        setShowPopup={setShowPopupOR}
                        rsvp={selectedRsvpEvent}
                        optionRequest={selectedOptionRequest}
                        notRequestedOptionId={notRequestedOptionId}
                        position={popupPositionOR}
                        handleUpdateRsvp={handleUpdateRsvpOptionsRequest}
                        handleUpdateRsvpCreateOptionRequest={
                          handleUpdateRsvpCreateOptionRequest
                        }
                      />
                    )}
                    {showPopupAttendance && (
                      <RsvpStatusUpdatePopup
                        attendanceMode={true}
                        showPopup={showPopupAttendance}
                        setShowPopup={setShowPopupAttendance}
                        rsvp={selectedRsvpEvent}
                        position={popupPositionAttendance}
                        handleUpdateRsvp={handleUpdateRsvpAttendance}
                      />
                    )}
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div
                          className={`table-wrapper ${
                            filtersEnabled ? "" : "filters-disabled"
                          }`}
                        >
                          {/* <div className="events-owners-subheading">
                            Filters
                          </div> */}
                          <div className="filters-enable-row">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="filterBypassSwitch"
                                checked={filtersEnabled}
                                onChange={(e) => handleSetFiltersEnabled(e)}
                              ></input>
                              <label
                                className="form-check-label enable-filters-label"
                                for="filterBypassSwitch"
                              >
                                Enable Filters
                              </label>
                            </div>
                          </div>
                          <div className="filters-row">
                            <FilterInput
                              filterKey="fullName"
                              placeholder="Name"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="email"
                              placeholder="Email"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="jobTitle"
                              placeholder="Job Title"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="company"
                              placeholder="Company"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="city"
                              placeholder="City"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="country"
                              placeholder="Country"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="referredBy"
                              placeholder="Referred By"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            {selectedEvent.options
                              .sort((a, b) => a.startTime - b.startTime)
                              .map((option) => {
                                const { title, optionId } = option;
                                return (
                                  <FilterOption
                                    title={title}
                                    filterKey={optionId}
                                    optionsEnum={StatusEnum}
                                    filters={filters}
                                    handleSetFilter={handleSetFilter}
                                  />
                                );
                              })}
                            <FilterOption
                              title="Attending"
                              filterKey="attendanceStatus"
                              optionsEnum={AttendanceStatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                          </div>
                          <br />
                          <div className="filters-row">
                            <FilterOption
                              title="All Requested Events"
                              filterKey="allRequested"
                              optionsEnum={StatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            {/* <FilterCheckbox
                              title="Approved and Attending (includes Tentative):"
                              checked={approvedAttendingFilter}
                              handleChecked={handleSetApprovedAttendingFilter}
                            /> */}
                            <FilterCheckbox
                              title="Approved (for at least one):"
                              checked={approvedFilter}
                              handleChecked={handleSetApprovedFilter}
                            />
                            <FilterOption
                              title="Final:"
                              filterKey="final"
                              optionsEnum={FinalStatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterOption
                              title="Confirmation Email:"
                              filterKey="confirmationEmail"
                              optionsEnum={EmailStatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterOption
                              title="Denied Email:"
                              filterKey="deniedEmail"
                              optionsEnum={EmailStatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            {/* <FilterCheckbox
                              title="Confirmation Email Sent:"
                              checked={confirmEmailSentFilter}
                              handleChecked={handleSetConfirmEmailSentFilter}
                            />
                            <FilterCheckbox
                              title="Denied Email Sent:"
                              checked={deniedEmailSentFilter}
                              handleChecked={handleSetDeniedEmailSentFilter}
                            /> */}
                          </div>
                          <div className="filters-row">
                            <FilterCheckbox
                              title="Duplicate Emails Only:"
                              checked={duplicatesOnlyFilter}
                              handleChecked={handleSetDuplicatesOnlyFilter}
                            />
                            <FilterCheckbox
                              title="Unique Emails Only:"
                              checked={uniqueEmailsFilter}
                              handleChecked={handleSetUniqueEmailsFilter}
                            />
                            <FilterCheckbox
                              title={`<span class="deleted-col">Show Deleted:</span>`}
                              checked={deletedFilter}
                              handleChecked={handleSetDeletedFilter}
                            />
                            {deletedFilter ? (
                              <FilterCheckbox
                                title={`<span class="deleted-col">Deleted Only:</span>`}
                                checked={deletedOnlyFilter}
                                handleChecked={handleSetDeletedOnlyFilter}
                              />
                            ) : null}
                          </div>
                        </div>
                      </motion.div>
                    }
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div className="table-wrapper">
                          <div
                            className="events-owners-subheading"
                            style={{ marginBottom: "8px" }}
                          >
                            {`Totals${filtersEnabled ? ` (filtered)` : ``}`}
                          </div>
                          <div className="totals-row rmono">
                            <div className="total-col">
                              <div className="total-line">
                                <div className="field">RSVPs: </div>
                                <div className="value">
                                  {filteredRsvps.length}
                                </div>
                              </div>
                            </div>
                            {selectedEvent.options
                              .sort((a, b) => a.startTime - b.startTime)
                              .map((option) => {
                                const { title, optionId } = option;
                                const reorderedStatusEnum = reorderStatusEnum([
                                  "APPROVED",
                                  "WAITLISTED",
                                  "DENIED",
                                  "PENDING",
                                ]);
                                return (
                                  <div className="total-col">
                                    <div className="total-line option">
                                      <div className="field">{title}: </div>
                                      <div className="value">
                                        {
                                          filteredRsvps.filter((r) =>
                                            r.optionRequests.some(
                                              (or) => or.optionId === optionId
                                            )
                                          ).length
                                        }
                                      </div>
                                    </div>
                                    {Object.entries(reorderedStatusEnum).map(
                                      ([key, status]) => {
                                        return (
                                          <div
                                            className={`total-line col-${status}`}
                                          >
                                            <div className="field">
                                              {toTitleCase(status)}{" "}
                                            </div>
                                            <div className={`value`}>
                                              {
                                                filteredRsvps.filter((r) =>
                                                  r.optionRequests.some(
                                                    (or) =>
                                                      or.optionId ===
                                                        optionId &&
                                                      or.status === status
                                                  )
                                                ).length
                                              }
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              })}
                            <div className="total-col">
                              <div className="total-line option">
                                <div className="field">Total Applied: </div>
                                <div className="value">
                                  {filteredRsvps.reduce(
                                    (sum, fr) => sum + fr.optionRequests.length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-approved`}>
                                <div className="field">{"Approved "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) =>
                                          or.status === StatusEnum.APPROVED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-waitlisted`}>
                                <div className="field">{"Waitlisted "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) =>
                                          or.status === StatusEnum.WAITLISTED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-denied`}>
                                <div className="field">{"Denied "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) => or.status === StatusEnum.DENIED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-pending`}>
                                <div className="field">{"Pending "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) => or.status === StatusEnum.PENDING
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              {/* <div className="total-line option">
                                <div className="field">Attending: </div>
                                <div className="value">
                                  {
                                    filteredRsvps.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.ATTENDING
                                    ).length
                                  }
                                </div>
                              </div>
                              <div className={`total-line col-tentative`}>
                                <div className="field">{"Tentative "}</div>
                                <div className={`value`}>
                                  {
                                    filteredRsvps.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.TENTATIVE
                                    ).length
                                  }
                                </div>
                              </div>
                              <div className={`total-line col-not-attending`}>
                                <div className="field">{"No "}</div>
                                <div className={`value`}>
                                  {
                                    filteredRsvps.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.NOT_ATTENDING
                                    ).length
                                  }
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    }
                    {selectedRsvpIds && selectedRsvpIds.length ? (
                      <div className="selected-rsvps-controls-container">
                        <div className="selected-display">{`${
                          selectedRsvpIds.length
                        } RSVP${
                          selectedRsvpIds.length === 1 ? "" : "s"
                        } selected`}</div>
                        <div className="text-center mb-4">
                          <div className="mt-3">
                            <div className="filters-row">
                              <FilterOption
                                title="Only affect requests for event"
                                filterKey="bulkRequestEvent"
                                noTitleCase={true}
                                optionsEnum={selectedEvent.options.reduce(
                                  (acc, o) => {
                                    acc[o.optionId] = o.title;
                                    return acc;
                                  },
                                  {}
                                )}
                                filters={filters}
                                handleSetFilter={handleSetFilter}
                              />
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedRsvpsAllOptionRequestsStatuses(
                                  StatusEnum.APPROVED
                                )
                              }
                              // style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as `}
                              <div class="badge-wrapper inline">
                                <span class="status-badge hvr-shrink badge bg-success">
                                  Approved
                                </span>
                              </div>
                              {/* {`(${selectedRsvpIds.length} RSVPs)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedRsvpsAllOptionRequestsStatuses(
                                  StatusEnum.WAITLISTED
                                )
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as `}
                              <div class="badge-wrapper inline">
                                <span class="status-badge hvr-shrink badge bg-warning">
                                  Waitlisted
                                </span>
                              </div>
                              {/* {`(${selectedRsvpIds.length} RSVPs)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedRsvpsAllOptionRequestsStatuses(
                                  StatusEnum.DENIED
                                )
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as `}
                              <div class="badge-wrapper inline">
                                <span class="status-badge hvr-shrink badge bg-danger">
                                  Denied
                                </span>
                              </div>
                              {/* {`(${selectedRsvpIds.length} RSVPs)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedRsvpsAllOptionRequestsAsFinal(true)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as Final`}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mb-3">
                          <div className="mt-3">
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn unselect-btn`}
                              onClick={() => setSelectedRsvpIds([])}
                              // style={{ marginLeft: "10px" }}
                            >
                              Unselect All
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-danger email-options-btn delete-rsvps-btn`}
                              onClick={() => deleteSelectedRsvps()}
                              style={{ marginLeft: "10px" }}
                            >
                              {`Delete RSVP${
                                selectedRsvpIds.length === 1 ? "" : "s"
                              }`}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            {selectedRsvpsIncludeDeleted ? (
                              <div
                                className={`create-event-btn btn btn-sm btn-danger email-options-btn undelete-rsvps-btn`}
                                onClick={() => undeleteSelectedRsvps()}
                                style={{ marginLeft: "10px" }}
                              >
                                {`Restore Deleted RSVP${
                                  selectedRsvpIds.length === 1 ? "" : "s"
                                }`}
                                {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <Table responsive className="header-border ">
                      <thead>
                        <tr>
                          <th className="width50 ">
                            <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkAll"
                                required
                                defaultChecked={false}
                                checked={
                                  !pageRsvps.some(
                                    (pr) =>
                                      !selectedRsvpIds.includes(pr.rsvpEventId)
                                  )
                                }
                                onChange={toggleSelectAllPageRsvps}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkAll"
                              ></label>
                            </div>
                          </th>
                          <th>User</th>
                          {event.options
                            .sort((a, b) => a.startTime - b.startTime)
                            .map((option) => {
                              return <th>{option.title}</th>;
                            })}
                          <th>Attending</th>
                          <th>Confirmation Email</th>
                          <th>Denied Email</th>
                          <th>Partner Options</th>
                          <th>Business Goals</th>
                          <th>Referred By</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pageRsvps.map((rsvp, i) => {
                          const {
                            rsvpEventId,
                            attendanceStatus,
                            attendanceStatusUpdates,
                            company,
                            createdTime,
                            email,
                            eventId,
                            eventTitle,
                            firstName,
                            lastName,
                            jobTitle,
                            businessGoals,
                            referredBy,
                            city,
                            country,
                            optionRequests,
                            comments,
                            partnerEventSponsorship,
                            partnerCustomEvent,
                            partnerIndustryTrendsBriefing,
                            deleted,
                          } = rsvp;

                          // confirm email vars
                          const hasAtleastOneApprovedFinal =
                            optionRequests.some(
                              (or) =>
                                or.status === StatusEnum.APPROVED && or.final
                            );
                          const allFinal = optionRequests.every(
                            (or) => or.final
                          ); // for warnings
                          const existingConfirmEmail =
                            rsvpConfirmationEmails.find(
                              (c) => c.rsvpEventId === rsvpEventId
                            );
                          // const approvedChangedSinceLastEmail =  existingConfirmEmail ?
                          let showSendConfirmEmailBtn = false;
                          if (hasAtleastOneApprovedFinal) {
                            showSendConfirmEmailBtn = true;
                            // hide btn if already sent email which has the same approved options as current
                            if (existingConfirmEmail) {
                              const emailApprovedFinalOptionIds =
                                existingConfirmEmail.optionRequests
                                  .filter(
                                    (or) =>
                                      or.status === StatusEnum.APPROVED &&
                                      or.final
                                  )
                                  .map((or) => or.optionId);
                              const currentApprovedFinalOptionIds =
                                optionRequests
                                  .filter(
                                    (or) =>
                                      or.status === StatusEnum.APPROVED &&
                                      or.final
                                  )
                                  .map((or) => or.optionId);
                              const noChange = arraysAreEqual(
                                emailApprovedFinalOptionIds,
                                currentApprovedFinalOptionIds
                              );
                              if (noChange) {
                                showSendConfirmEmailBtn = false;
                              }
                            }
                          }

                          // denied email vars
                          const hasAllDeniedFinal = optionRequests.every(
                            (or) => or.status === StatusEnum.DENIED && or.final
                          );
                          // for warnings
                          const existingDeniedEmail = rsvpDeniedEmails.find(
                            (c) => c.rsvpEventId === rsvpEventId
                          );
                          // const approvedChangedSinceLastEmail =  existingConfirmEmail ?
                          let showSendDeniedEmailBtn = false;
                          if (hasAllDeniedFinal) {
                            showSendDeniedEmailBtn = true;
                            // hide btn if already sent email which has the same denied options as current
                            if (existingDeniedEmail) {
                              const emailDeniedFinalOptionIds =
                                existingDeniedEmail.optionRequests
                                  .filter(
                                    (or) =>
                                      or.status === StatusEnum.DENIED &&
                                      or.final
                                  )
                                  .map((or) => or.optionId);
                              const currentDeniedFinalOptionIds = optionRequests
                                .filter(
                                  (or) =>
                                    or.status === StatusEnum.DENIED && or.final
                                )
                                .map((or) => or.optionId);
                              const noChange = arraysAreEqual(
                                emailDeniedFinalOptionIds,
                                currentDeniedFinalOptionIds
                              );
                              if (noChange) {
                                showSendDeniedEmailBtn = false;
                              }
                            }
                          }

                          return (
                            <tr
                              key={`tr_row_${i}`}
                              className={`${deleted ? "deleted" : ""}`}
                            >
                              <td>
                                <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox2"
                                    required
                                    defaultChecked={false}
                                    checked={selectedRsvpIds.includes(
                                      rsvp.rsvpEventId
                                    )}
                                    onChange={(event) =>
                                      toggleSelectRsvp(event, rsvp)
                                    }
                                  />
                                </div>
                              </td>
                              <td
                                className="wrap"
                                style={{
                                  maxWidth: "550px",
                                  minWidth: "550px",
                                  width: "550px",
                                }}
                              >
                                <div className="user-container">
                                  {deleted ? (
                                    <div className="deleted">DELETED</div>
                                  ) : null}

                                  <div className="name">
                                    {firstName} {lastName}
                                  </div>
                                  <div className="email">
                                    {email}{" "}
                                    <span
                                      className="clipboard-icon hvr-shrink"
                                      onClick={() => copyText(email, "email")}
                                    >
                                      <FileCopyOutlinedIcon
                                        style={{
                                          width: "0.7em",
                                          height: "0.7em",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className="job">
                                    {jobTitle} at {company}
                                  </div>
                                </div>
                              </td>
                              {event.options
                                .sort((a, b) => a.startTime - b.startTime)
                                .map((option) => {
                                  const { optionId } = option;
                                  // find response for this option
                                  const optionRequest = optionRequests.find(
                                    (or) => or.optionId === optionId
                                  );
                                  if (!optionRequest) {
                                    // not requested optionId
                                    return (
                                      <td>
                                        <div
                                          className="not-requested-btn"
                                          onClick={(event) => {
                                            handleBadgeClickOR(
                                              event,
                                              rsvp,
                                              null,
                                              optionId
                                            );
                                          }}
                                        >
                                          Not Requested
                                        </div>
                                      </td>
                                    );
                                  }
                                  const { status, final, statusUpdates } =
                                    optionRequest;
                                  return (
                                    <td>
                                      <div className="badge-wrapper">
                                        <Badge
                                          bg={getBadgeBgFromStatus(status)}
                                          className="status-badge hvr-shrink"
                                          onClick={(event) => {
                                            handleBadgeClickOR(
                                              event,
                                              rsvp,
                                              optionRequest
                                            );
                                          }}
                                        >
                                          {toTitleCase(status)}
                                        </Badge>
                                        <AnimatePresence>
                                          {final && (
                                            <div className="final-badge-wrapper">
                                              <motion.div
                                                animate={{
                                                  scale: [0, 1.07, 1],
                                                  rotate: [-45, 3, 0],
                                                  opacity: [0, 1],
                                                }}
                                                exit={{
                                                  scale: [1, 1.07, 0],
                                                  rotate: [0, 3, -90],
                                                }}
                                              >
                                                <div className="final-badge">
                                                  Final
                                                </div>
                                              </motion.div>
                                            </div>
                                          )}
                                        </AnimatePresence>
                                      </div>
                                    </td>
                                  );
                                })}
                              <td>
                                <div className="badge-wrapper">
                                  <Badge
                                    bg={null}
                                    className={`status-badge hvr-shrink ${getBadgeClassFromAttendanceStatus(
                                      attendanceStatus
                                    )}`}
                                    onClick={(event) => {
                                      handleBadgeClickAttendance(event, rsvp);
                                    }}
                                  >
                                    {getAttendanceStatusDisplayText(
                                      attendanceStatus
                                    )}
                                  </Badge>
                                </div>
                              </td>
                              {/* confirmation email cell */}
                              <td>
                                <div className="confirm-email-cell-container">
                                  {!hasAtleastOneApprovedFinal ? (
                                    <div className="awaiting-final-msg">
                                      Awaiting Final Approval
                                    </div>
                                  ) : null}
                                  {showSendConfirmEmailBtn ? (
                                    <>
                                      <button
                                        className={`sign-up-btn btn btn-sm btn-primary`}
                                        onClick={() =>
                                          sendConfirmationEmail(rsvpEventId)
                                        }
                                      >
                                        {`Send Confirm Email`}
                                      </button>
                                      {!allFinal ? (
                                        <div className="not-all-final-msg">
                                          Warning: Not all requests are final
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                  {existingConfirmEmail ? (
                                    <>
                                      <OverlayTrigger
                                        trigger="click"
                                        key={i}
                                        placement="left"
                                        responsive={true}
                                        rootClose={true}
                                        overlay={
                                          <Tooltip
                                            className="toltip-popover"
                                            id={`popover-positioned-left`}
                                          >
                                            <ConfirmationEmailPopup
                                              confirmationEmail={
                                                existingConfirmEmail
                                              }
                                              sendConfirmationEmail={
                                                sendConfirmationEmail
                                              }
                                            />
                                          </Tooltip>
                                        }
                                      >
                                        <div className="existing-confirm-email">
                                          Confirmation Sent
                                        </div>
                                      </OverlayTrigger>
                                    </>
                                  ) : null}
                                </div>
                              </td>
                              {/* denied email cell */}
                              <td>
                                <div className="confirm-email-cell-container">
                                  {!allFinal ? (
                                    <div className="awaiting-final-msg">
                                      Awaiting All Final
                                    </div>
                                  ) : null}
                                  {showSendDeniedEmailBtn ? (
                                    <>
                                      <button
                                        className={`sign-up-btn btn btn-sm btn-primary`}
                                        onClick={() =>
                                          sendDeniedEmail(rsvpEventId)
                                        }
                                      >
                                        {`Send Denied Email`}
                                      </button>
                                      {/* {!allFinal ? (
                                        <div className="not-all-final-msg">
                                          Warning: Not all requests are final
                                        </div>
                                      ) : null} */}
                                    </>
                                  ) : null}
                                  {existingDeniedEmail ? (
                                    <>
                                      <OverlayTrigger
                                        trigger="click"
                                        key={i}
                                        placement="left"
                                        responsive={true}
                                        rootClose={true}
                                        overlay={
                                          <Tooltip
                                            className="toltip-popover"
                                            id={`popover-positioned-left`}
                                          >
                                            <ConfirmationEmailPopup
                                              confirmationEmail={
                                                existingDeniedEmail
                                              }
                                              sendConfirmationEmail={
                                                sendDeniedEmail
                                              }
                                            />
                                          </Tooltip>
                                        }
                                      >
                                        <div className="existing-confirm-email">
                                          Denied Email Sent
                                        </div>
                                      </OverlayTrigger>
                                    </>
                                  ) : null}
                                </div>
                              </td>
                              <td>
                                {partnerEventSponsorship && (
                                  <div class="option-value col-w">
                                    Discuss Event Sponsorship
                                  </div>
                                )}
                                {partnerCustomEvent && (
                                  <div class="option-value col-w">
                                    Discuss Custom Event
                                  </div>
                                )}
                                {partnerIndustryTrendsBriefing && (
                                  <div class="option-value col-w">
                                    Schedule Industry Trends Briefing
                                  </div>
                                )}
                                {!partnerEventSponsorship &&
                                !partnerCustomEvent &&
                                !partnerIndustryTrendsBriefing ? (
                                  <span>N/A</span>
                                ) : null}
                              </td>
                              <td
                                className="wrap"
                                style={{ maxWidth: "350px", minWidth: "350px" }}
                              >
                                {businessGoals}
                              </td>
                              <td
                                className="wrap"
                                style={{ maxWidth: "300px", minWidth: "300px" }}
                              >
                                {referredBy}
                              </td>
                              <td
                                className="wrap"
                                style={{ maxWidth: "130px", minWidth: "130px" }}
                              >
                                {city}
                              </td>
                              <td
                                className="wrap"
                                style={{ maxWidth: "130px", minWidth: "130px" }}
                              >
                                {country}
                              </td>
                              <td>
                                <div className="view-comments-btn-container">
                                  <OverlayTrigger
                                    trigger="click"
                                    key={i}
                                    placement="left"
                                    responsive={true}
                                    rootClose={true}
                                    overlay={
                                      <Tooltip
                                        className="toltip-popover"
                                        id={`popover-positioned-left`}
                                      >
                                        <CommentPopup
                                          rsvp={rsvp}
                                          onSubmitComment={
                                            handleSubmitRsvpComment
                                          }
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      className={`sign-up-btn btn btn-sm ${
                                        comments.length === 0
                                          ? "btn-grey"
                                          : "btn-primary"
                                      }`}
                                    >
                                      {`View Comments (${comments.length})`}
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-between">
                      <span className="table-index">
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageCount}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Rows per page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={rowsPerPage}
                          min={1}
                          max={500}
                          onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                          }}
                        />
                      </span>
                      <span className="table-index">
                        Go to page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={pageIndex + 1}
                          min={1}
                          max={pageCount}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            goToPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => goToPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => goToPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <div className="mt-3">
                        {/* <button
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          onClick={handleExportData}
                        >
                          {`Export All RSVPs`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </button> */}
                        <ExportJsonCsv
                          headers={headers}
                          items={csvAdjustedRsvpsNoDeleted}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                        >
                          {`Export All RSVP${
                            csvAdjustedRsvpsNoDeleted.length === 1 ? "" : "s"
                          } (${csvAdjustedRsvpsNoDeleted.length})`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv>
                        {filtersEnabled ? (
                          <ExportJsonCsv
                            headers={headers}
                            items={filteredCsvAdjustedRsvps}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                            style={{ marginLeft: "10px" }}
                          >
                            {`Export Filtered RSVP${
                              filteredCsvAdjustedRsvps.length === 1 ? "" : "s"
                            } (${filteredCsvAdjustedRsvps.length})`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv>
                        ) : null}
                        {selectedRsvpIds && selectedRsvpIds.length ? (
                          <ExportJsonCsv
                            headers={headers}
                            items={selectedCsvAdjustedRsvps}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                            style={{ marginLeft: "10px" }}
                          >
                            {`Export Selected RSVP${
                              selectedCsvAdjustedRsvps.length === 1 ? "" : "s"
                            } (${selectedCsvAdjustedRsvps.length})`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv>
                        ) : null}
                        {/* <ExportJsonCsv
                          headers={headers}
                          items={csvRsvpsAllApprovedFinal.filter(
                            (r) => r.tempConfirmEmailHtml
                          )}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          style={{ marginLeft: "10px" }}
                        >
                          {`Export All Approved Final RSVPs ready to send a conf email`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              {!rsvps ? (
                <div className="text-center">
                  <img
                    className="m-auto mt-3 mb-3"
                    width={74}
                    src={loadingCircleImg}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </motion.div>
      ) : (
        <div className="no-rsvps-msg">Loading RSVPs</div>
      )}
    </>
  );
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const OwnerEmailPrompt = ({
  eventId,
  submitOwnerAssignment,
  setShowEmailEventId,
}) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await submitOwnerAssignment(eventId, email);
    if (result) {
      // reset and hide the email input on success
      setEmail("");
      setShowEmailEventId("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        onChange={handleInputChange}
        type="email"
        className="form-control w-100 mt-1"
        placeholder="Owner Email"
        value={email}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc"
        onClick={(e) => handleSubmit(e)}
      >
        Add
      </div>
    </form>
  );
};

// Change name
const OwnerAssign = ({ password }) => {
  const [events, setEvents] = useState([]);
  const [showEmailEventId, setShowEmailEventId] = useState("");

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`OwnerAssign - events`, eventsResponse);
      setEvents(eventsResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  async function submitOwnerAssignment(eventId, email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/events/assignOwnerToEvent/`,
        {
          eventId,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh owners
        getEvents();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function removeOwnerAssignment(eventId, email) {
    if (!eventId || !email) {
      toast.error("Error occurred");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/events/removeOwnerFromEvent/`,
        {
          eventId,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
      } else {
        toast.success(`${success}`);
        // refresh owners
        getEvents();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (!events || !events.length) {
    return <div>Loading Events...</div>;
  }

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <Card className="mb-3 p-3 h-fc bot-table">
        <div className="row">
          <div className="outputHeader">Event Owners</div>
        </div>
        <div className="events-owners-subheading">
          Owners assigned to events will receive email notifications when new
          event RSVPs are detected
        </div>
        <div className="event-owners-wrapper">
          {events.map((event) => {
            return (
              <div key={event.eventId}>
                <div className="event-owner-container">
                  <img src={event.imageUrl} className="img-fluid event-img" />
                  <div className="event-title">{event.title}</div>
                  {event.owners ? (
                    <>
                      {event.owners.map((o, i) => (
                        <div className="event-owner-outer" key={i}>
                          <div className="event-owner">{o.email}</div>
                          <i
                            onClick={() =>
                              removeOwnerAssignment(event.eventId, o.email)
                            }
                            className="event-owner-remove fa-sharp fa-solid fa-delete-left ml-05"
                          ></i>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {showEmailEventId === event.eventId ? (
                    <motion.div
                      animate={{ opacity: 1 }}
                      initial={{ opacity: 0 }}
                    >
                      <OwnerEmailPrompt
                        eventId={event.eventId}
                        submitOwnerAssignment={submitOwnerAssignment}
                        setShowEmailEventId={setShowEmailEventId}
                      />
                    </motion.div>
                  ) : (
                    <i
                      onClick={() => setShowEmailEventId(event.eventId)}
                      className="event-owner-add fa-sharp fa-solid fa-plus ml-05"
                    ></i>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </motion.div>

    // <Box>
    //   Assign Event
    //   <Select
    //     options={eventsOptions}
    //     onChange={(values) => setSelectedEventId(values)}
    //     color="#fff"
    //     styles={{ backgroundColor: "#000" }}
    //   ></Select>
    //   <input className="form-control" onKeyUp={changeInputText}></input>
    //   <Button className="form-control" onClick={submitOwnerAssignment}>
    //     Submit
    //   </Button>
    // </Box>
  );
};

const SelectEvent = ({ password, selectedEvent, setSelectedEvent }) => {
  const [events, setEvents] = useState([]);

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`OwnerAssign - events`, eventsResponse);
      setEvents(eventsResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Events</div>
            <>
              <div className="events-owners-subheading">
                Select Event to view RSVPs
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {events.map((event) => {
                  const selectedClass =
                    selectedEvent && event.eventId === selectedEvent.eventId
                      ? "selected"
                      : "";
                  return (
                    <div key={event.eventId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedEvent(event)}
                      >
                        <img
                          src={event.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{event.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};
TablePaginationActions2.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions2(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees
              .sort((a, b) => {
                // If both have 'lastSeenTime', compare by 'lastSeenTime' (descending)
                if (a.lastSeenTime && b.lastSeenTime) {
                  return b.lastSeenTime - a.lastSeenTime;
                }

                // If one has 'lastSeenTime', prioritize that one
                if (a.lastSeenTime && !b.lastSeenTime) {
                  return -1;
                }
                if (!a.lastSeenTime && b.lastSeenTime) {
                  return 1;
                }

                // If neither have 'lastSeenTime', compare by 'firstName' alphabetically
                return a.firstName.localeCompare(b.firstName);
              })
              .map((employee, i) => (
                <EmployeeInfo
                  key={i}
                  landmarkEmployee={employee}
                  handleOnClick={signInAsEmployee}
                />
              ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  const signInAsEmployee = (employee) => {
    if (!employee) {
      toast.error("Cannot sign in as employee");
      return;
    }
    setIsSignedIn(true);
    setEmployee(employee);

    try {
      const api = new ApiService({ employee });
      // sets lastSeenTime
      api.get(`landmark/employees/login`);
    } catch (error) {
      console.error(`signInAsEmployee() - employee login error`, error);
    }

    toast.success(`Logged in as ${employee.firstName} ${employee.lastName}`);
  };
  useDocumentTitle("Events Dashboard - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        {isSignedIn && (
          <div className="landmark-sign-in" style={{ marginBottom: "18px" }}>
            <div className="existing-accounts-container">
              <EmployeeInfo landmarkEmployee={employee} disableHover={true} />
            </div>
          </div>
        )}
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "Global Dashboard", url: `/landmark/global/dashboard` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated && isSignedIn ? (
          <>
            <AddEditEvent password={password} employee={employee} />
            <OwnerAssign password={password} employee={employee} />
            <SelectEvent
              password={password}
              employee={employee}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
            {selectedEvent && (
              <MasterEventsTable
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                password={password}
                employee={employee}
              />
            )}
          </>
        ) : null}
        {/* show sign in */}
        {isAuthenticated && !isSignedIn ? (
          <>
            <motion.div
              animate={{
                scale: [0, 1.04, 1],
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <EmployeeSignIn
                password={password}
                signInAsEmployee={signInAsEmployee}
              />
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
