import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  copyTextToClipboard,
  timeNow,
  useDocumentTitle,
  epochToDateObj,
  epochFromDate,
  uniqueGuid,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import imageUploadLoader from "../../../../images/misc/loader-light-blue-bg.gif";

const timezoneOptions = ["PT"];

const OptilistForm = ({
  selectedOptilist,
  password,
  getOptilists,
  resetModes,
}) => {
  const [title, setTitle] = useState(
    selectedOptilist ? selectedOptilist.title : ""
  );
  const [description, setDescription] = useState(
    selectedOptilist ? selectedOptilist.description : ""
  );

  const [selectedOption, setSelectedOption] = useState(null);

  const [loading, setLoading] = useState(false);

  async function addOrEditOptilist(optilist, editMode = false) {
    try {
      const api = new ApiService({ password });
      const url = editMode
        ? "landmark/optilist/editOptilist"
        : "landmark/optilist/addOptilist";
      const optilistsResponse = await api.post(url, {
        optilist,
      });
      console.log(`addOrEditOptilist() - ${url} - response`, optilistsResponse);
      const { success, error } = optilistsResponse;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        toast.success(success);
        // refresh optilists
        getOptilists();
        resetModes(); // exit the edit/create form
      }
    } catch (error) {
      console.error(error);
    }
  }
  // Handle image upload
  const handleImageUpload = async (event, setter, options = {}) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("image", file);
    formData.append("options", JSON.stringify(options));

    try {
      const api = new ApiService({ password });
      const response = await api.post("landmark/upload/image", formData);
      if (response.success && response.imageUrl) {
        setter(response.imageUrl);
        toast.success("Image uploaded successfully");
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleSaveOptilist = () => {
    // scrape details from state
    const optilist = {
      title,
      description,
    };
    // saving an existing optilist - use edit endpoint
    if (selectedOptilist && selectedOptilist.optilistId) {
      optilist.optilistId = selectedOptilist.optilistId;
      // edit optilist
      addOrEditOptilist(optilist, true);
    } else {
      // add optilist
      addOrEditOptilist(optilist, false);
    }
  };

  useEffect(() => {
    // new optilist created
    if (!selectedOptilist || !selectedOptilist.optilistId) {
      setTitle("");
      setDescription("");
    }
    // existing optilist obj
    else {
      setTitle(selectedOptilist.title);
      setDescription(selectedOptilist.description);
    }
  }, [selectedOptilist]);

  // called when user has saved an option

  const newOptionMode =
    selectedOption !== null &&
    !selectedOption.optionId &&
    !selectedOption.tempOptionId
      ? true
      : false; // user is creating a new option or editing existing

  const saveBtnText =
    selectedOptilist === null ? "Save Optilist" : "Save Changes";

  return (
    <div className="event-form">
      <div className="row">
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">
            Title <span className="example-text">(e.g. Jasper clients)</span>
          </label>
          <input
            type="text"
            value={title}
            className="form-control"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">
            Description{" "}
            <span className="example-text">
              (e.g. Cleaning list for Jasper. Banking clients only)
            </span>
          </label>
          <input
            type="text"
            value={description}
            className="form-control"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Logo <span className="example-text">(for internal use only)</span>
          </label>

          {loading && (
            <img
              src={imageUploadLoader}
              alt="Uploading..."
              width="100"
              className="img-thumbnail"
            />
          )}

          <>
            {" "}
            <input
              type="file"
              accept="image/*"
              //   onChange={(e) =>
              // handleImageUpload(e, setImageUrl, {
              //   compress: true,
              // })
              //   }
              className="d-none"
              id="eventLogoUpload"
            />
            <button
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => document.getElementById("eventLogoUpload").click()}
            >
              Upload List
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
            <label className="form-label">List should be exported in </label>
          </>
          {/* <input
            type="text"
            value={imageUrl}
            className="form-control"
            onChange={(e) => setImageUrl(e.target.value)}
          /> */}
        </div>

        {/* <div className="form-group mb-3 col-md-12">
          <div className="options-wrapper">
            <label className="form-label">
              Optilist options which the attendee can request to attend<br></br>
              <span className="example-text">
                (e.g. VIP Brunch or Cocktail Reception)
              </span>
            </label>
            <label className="form-label">
              Click on an existing option to edit it or add a new option.
            </label>
            <div className="edit-options-container">
              {options.map((o) => {
                const selClass = o === selectedOption ? "selected" : "";
                return (
                  <div
                    className={`option-container ${selClass}`}
                    onClick={() => {
                      console.log("selectedOption", o);
                      setSelectedOption(o);
                    }}
                  >
                    <div className="title">{o.title}</div>
                    <div className="description">{o.description}</div>
                  </div>
                );
              })}
              <div
                className={`option-container new text-center ${
                  newOptionMode ? "selected" : ""
                }`}
                onClick={() => {
                  console.log("selectedOption", {});
                  setSelectedOption({});
                }}
              >
                <div className="title text-center">
                  New Option{" "}
                  <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <button
        className={`create-event-btn btn btn-sm btn-primary mb-3`}
        onClick={handleSaveOptilist}
      >
        {saveBtnText}
        <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
      </button>
    </div>
  );
};

const AddEditOptilist = ({ password }) => {
  const [optilists, setOptilists] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedOptilist, setSelectedOptilist] = useState(null);

  async function getOptilists() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const optilistsResponse = await api.get(`landmark/optilist/getOptilists`);
      console.log(`getOptilists()`, optilistsResponse);
      setOptilists(optilistsResponse);
      setSelectedOptilist(null);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getOptilists();
  }, []);

  const toggleSetShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSetCreateMode = () => {
    setEditMode(false);
    setCreateMode(true);
    setSelectedOptilist(null);
  };

  const handleSetEditMode = () => {
    setCreateMode(false);
    setEditMode(true);
  };

  const resetModes = () => {
    setCreateMode(false);
    setEditMode(false);
    setSelectedOptilist(null);
  };

  return (
    <>
      <div className="add-edit-btn-container">
        {!showForm && (
          <>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetCreateMode}
            >
              {`Create New Optilist`}
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetEditMode}
            >
              {`Edit Existing Optilist`}
              <i className="event-owner-add fa-sharp fa-solid fa-pencil ml-05"></i>
            </button>
          </>
        )}
      </div>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Optilist Projects</div>
            <>
              <div className="events-owners-subheading">
                Select an existing project or create a new one
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {optilists.map((optilist) => {
                  const selectedClass =
                    selectedOptilist &&
                    optilist.optilistId === selectedOptilist.optilistId
                      ? "selected"
                      : "";
                  return (
                    <div key={optilist.optilistId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedOptilist(optilist)}
                      >
                        <img
                          src={optilist.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{optilist.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {selectedOptilist ? (
                <>
                  <OptilistForm
                    selectedOptilist={selectedOptilist}
                    password={password}
                    getOptilists={getOptilists}
                    resetModes={resetModes}
                  />
                </>
              ) : null}
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

export default AddEditOptilist;
