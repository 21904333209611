import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; //Example style, you can use another
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { ExportJsonCsv } from "react-export-json-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  Folder,
} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import ErrorBoundary from "../../ErrorBoundary";
import {
  arraysAreEqual,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import AddEditOptilist from "./AddEditOptilist";

const FilterInput = ({ filterKey, placeholder, handleSetFilter, filters }) => {
  const value = filters[filterKey] ? filters[filterKey] : "";
  return (
    <input
      onChange={(e) => handleSetFilter(filterKey, e.target.value)}
      type="text"
      className="form-control mt-1 filter-input"
      placeholder={placeholder}
      value={value}
    ></input>
  );
};

const FilterOption = ({
  filterKey,
  title,
  optionsEnum,
  handleSetFilter,
  filters,
  noTitleCase = false,
}) => {
  const value = filters[filterKey] ? filters[filterKey] : "All";
  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">
          <div className="option-title">{title}: </div>{" "}
        </span>
        <select
          defaultValue={"All"}
          className="form-control rmono"
          onChange={(e) => handleSetFilter(filterKey, e.target.value)}
          value={value}
        >
          <option>All</option>
          {Object.entries(optionsEnum).map(([key, status]) => {
            return (
              <option>{noTitleCase ? status : toTitleCase(status)}</option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const FilterCheckbox = ({ title, checked, handleChecked }) => {
  // title can be a string or HTML
  const isHTML = (str) => {
    // Simple check for HTML tags
    return /<\/?[a-z][\s\S]*>/i.test(str);
  };
  const renderTitle = () => {
    if (isHTML(title)) {
      return (
        <div
          className="option-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    }

    return <div className="option-title">{title}</div>;
  };

  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">{renderTitle()}</span>
        <div
          className="form-check custom-checkbox checkbox-success check-lg bs_exam_topper"
          // style={{ marginLeft: "4px" }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`FilterCheckbox_${title}`}
            required
            defaultChecked={false}
            checked={checked}
            onChange={(event) => handleChecked(event)}
          />
        </div>
      </div>
    </div>
  );
};

const MasterEventsTable = ({
  password,
  employee,
  selectedEvent,
  setSelectedEvent,
}) => {
  const [events, setEvents] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [rsvps, setRsvps] = useState([]);
  const [selectedRsvpIds, setSelectedRsvpIds] = useState([]);
  const [rsvpConfirmationEmails, setRsvpConfirmationEmails] = useState([]);
  const [rsvpDeniedEmails, setRsvpDeniedEmails] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [approvedAttendingFilter, setApprovedAttendingFilter] = useState(false);
  const [approvedFilter, setApprovedFilter] = useState(false);
  const [duplicatesOnlyFilter, setDuplicatesOnlyFilter] = useState(false);
  const [uniqueEmailsFilter, setUniqueEmailsFilter] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState(false);
  const [deletedOnlyFilter, setDeletedOnlyFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let bulkOnlyAffectOptionId = filters?.bulkRequestEvent || "All";
  if (bulkOnlyAffectOptionId === "All") {
    bulkOnlyAffectOptionId = false;
  } else if (bulkOnlyAffectOptionId) {
    // get optionId from title
    if (
      selectedEvent &&
      selectedEvent.options &&
      selectedEvent.options.length
    ) {
      const option = selectedEvent.options.find(
        (o) =>
          o.title.toLowerCase().trim() ===
          bulkOnlyAffectOptionId.toLowerCase().trim()
      );
      if (!option) {
        console.error(
          `bulkOnlyAffectOptionId: ERROR: could not retrieve optionId from title: ${bulkOnlyAffectOptionId}`
        );
      } else {
        bulkOnlyAffectOptionId = option.optionId;
      }
    }
  }
  console.log(`bulkOnlyAffectOptionId`, bulkOnlyAffectOptionId);

  const handleSetApprovedAttendingFilter = (event) => {
    const checked = event.target.checked;
    setApprovedAttendingFilter(checked);
  };

  const handleSetApprovedFilter = (event) => {
    const checked = event.target.checked;
    setApprovedFilter(checked);
  };

  const handleSetDuplicatesOnlyFilter = (event) => {
    const checked = event.target.checked;
    setDuplicatesOnlyFilter(checked);
  };

  const handleSetUniqueEmailsFilter = (event) => {
    const checked = event.target.checked;
    setUniqueEmailsFilter(checked);
  };

  const handleSetDeletedFilter = (event) => {
    const checked = event.target.checked;
    setDeletedFilter(checked);
  };

  const handleSetDeletedOnlyFilter = (event) => {
    const checked = event.target.checked;
    setDeletedOnlyFilter(checked);
  };

  const handleSetFiltersEnabled = (event) => {
    const checked = event.target.checked;
    setFiltersEnabled(checked);
  };

  const getFilteredRsvps = () => {
    function isString(s) {
      return typeof s === "string" || s instanceof String;
    }

    // filter just the rsvps for the selected event
    let filtered;
    try {
      if (selectedEvent && selectedEvent.eventId) {
        filtered = rsvps.filter((r) => r.eventId === selectedEvent.eventId);
      } else {
        throw "No selected event";
      }
    } catch (error) {
      console.log(`getFilteredRsvps() - error`, error);
      filtered = rsvps;
    }

    // deleted filter
    if (deletedFilter) {
      // 'Show Deleted' is checked - don't filter them out
    } else {
      // remove deleted rsvps
      filtered = filtered.filter((rsvp) => !rsvp.deleted);
    }

    // bypassed
    if (!filtersEnabled) {
      return filtered;
    }

    for (const filterKey in filters) {
      const filterValue = filters[filterKey];
      //   // attendanceStatus filtering
      //   if (filterKey === "attendanceStatus") {
      //     if (filterValue === "All") {
      //       // no nothing (allow all)
      //     } else {
      //       filtered = filtered.filter(
      //         (rsvp) =>
      //           rsvp.attendanceStatus.toLowerCase() === filterValue.toLowerCase()
      //       );
      //     }
      //   }

      // string filtering
    }

    // // approved filter
    // if (approvedFilter) {
    //   filtered = filtered.filter((rsvp) => {
    //     const approved = rsvp.optionRequests.some(
    //       (or) => or.status === StatusEnum.APPROVED
    //     ); // has at least one approved request
    //     return approved;
    //   });
    // }
    return filtered;
  };
  let filteredRsvps = getFilteredRsvps();

  // OptionRequest popup
  const [showPopupOR, setShowPopupOR] = useState(false);
  const [showPopupAttendance, setShowPopupAttendance] = useState(false);
  const [popupPositionOR, setPopupPositionOR] = useState({ x: 0, y: 0 });
  const [popupPositionAttendance, setPopupPositionAttendance] = useState({
    x: 0,
    y: 0,
  });
  const [selectedRsvpEvent, setSelectedRsvpEvent] = useState(null);
  const [selectedOptionRequest, setSelectedOptionRequest] = useState(null);
  const [notRequestedOptionId, setNotRequestedOptionId] = useState(null);

  async function fetchRsvps() {
    const api = new ApiService({ password });
    try {
      const tag = "global";
      const response = await api.get(`landmark/events/getEventRsvps/${tag}`);
      console.log(`fetchRsvps()`, response);
      setRsvps(response);
      console.log("rsvps", response);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`events`, eventsResponse);
      setEvents(eventsResponse);

      // refresh selectedEvent (so it displays latest data)
      const _selectedEventId =
        selectedEvent && selectedEvent.eventId ? selectedEvent.eventId : null;
      if (_selectedEventId) {
        const newSelectedEvent = eventsResponse.find(
          (e) => e.eventId === _selectedEventId
        );
        if (newSelectedEvent) {
          setSelectedEvent(newSelectedEvent);
        }
      }
      //setSelectedEventId(eventsResponse[0].eventId);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchConfirmEmails() {
    try {
      if (!selectedEvent || !selectedEvent.eventId) {
        console.log(`fetchConfirmEmails() - invalid selected event`);
        return;
      }
      const api = new ApiService({ password });
      const eventId = selectedEvent.eventId;
      const resp = await api.get(
        `landmark/events/getRsvpConfirmationEmails/${eventId}`
      );
      console.log(`fetchConfirmEmails() - rsvpConfirmationEmails`, resp);
      setRsvpConfirmationEmails(resp);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchDeniedEmails() {
    try {
      if (!selectedEvent || !selectedEvent.eventId) {
        console.log(`fetchDeniedEmails() - invalid selected event`);
        return;
      }
      const api = new ApiService({ password });
      const eventId = selectedEvent.eventId;
      const resp = await api.get(
        `landmark/events/getRsvpDeniedEmails/${eventId}`
      );
      console.log(`fetchDeniedEmails() - rsvpDeniedEmails`, resp);
      setRsvpDeniedEmails(resp);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRsvps();
    fetchEvents();
  }, []);

  useEffect(() => {
    setSelectedEventId(selectedEvent.eventId);
    console.log("selectedEvent", selectedEvent);
    fetchConfirmEmails();
    fetchDeniedEmails();
  }, [selectedEvent]);

  const handleBadgeClickOR = (
    event,
    rsvp,
    optionRequest,
    notRequestedOptionId = null
  ) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionOR({ x: popupX, y: popupY });
    setSelectedRsvpEvent(rsvp);
    setSelectedOptionRequest(optionRequest);
    setNotRequestedOptionId(notRequestedOptionId);
    setShowPopupOR(true);
    setShowPopupAttendance(false);
  };

  const handleBadgeClickAttendance = (event, rsvp) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionAttendance({ x: popupX, y: popupY });
    setSelectedRsvpEvent(rsvp);
    setShowPopupOR(false);
    setShowPopupAttendance(true);
  };

  // allEvents.forEach(element => {
  //   columns.push({
  //     header: element.eventTitle,
  //     accessorKey: outputdata[0].
  //   })
  // });
  // const handleExportRows = (rows) => {
  //   csvExporter.generateCsv(rows.map((row) => row.original));
  // };

  // const handleExportData = () => {
  //   csvExporter.generateCsv(rsvps);
  // };

  // const handleMarkRsvpsAsHandled = async (rows) => {
  //   const eventRsvpIds = rows.map((row) => row.original.rsvpEventId);
  //   // mark all as handled
  //   const handledArr = eventRsvpIds.map((e) => true);
  //   try {
  //     const api = new ApiService({ password });
  //     const { success, error } = await api.post(
  //       `landmark/events/setEventRsvpsHandled/`,
  //       {
  //         eventRsvpIds,
  //         handledArr,
  //       }
  //     );
  //     if (!success) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else {
  //       toast.success(`${success}`);
  //       // refresh table
  //       RenderTable();
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  // const deleteRsvps = async (rows) => {
  //   const eventRsvpIds = rows.map((row) => row.original.rsvpEventId);
  //   try {
  //     const api = new ApiService({ password });
  //     const { success, error } = await api.post(
  //       `landmark/events/removeEventRsvps`,
  //       {
  //         eventRsvpIds,
  //       }
  //     );
  //     if (!success) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else {
  //       toast.success(`${success}`);
  //       // refresh table
  //       RenderTable();
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  // const csvOptions = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   useBom: true,
  //   useKeysAsHeaders: false,
  //   headers: columns.map((c) => c.header),
  // };

  // const csvExporter = new ExportToCsv(csvOptions);

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  let canNextPage = false;
  let canPreviousPage = false;
  const pageCount = Math.ceil(filteredRsvps.length / rowsPerPage);
  console.log(`pageIndex`, pageIndex);
  console.log(`pageCount`, pageCount);
  if (pageCount === 0 && pageIndex !== 0) {
    console.log(`resetting pageIndex to 0 (out of bounds)`);
    setPageIndex(0);
  } else if (pageCount > 0 && pageIndex + 1 > pageCount) {
    console.log(`resetting pageIndex to 0 (out of bounds)`);
    setPageIndex(0);
  }
  if (pageIndex + 1 < pageCount) {
    canNextPage = true;
  }
  if (pageIndex > 0) {
    canPreviousPage = true;
  }

  const goToPage = (pageNo) => {
    if (pageNo >= 0 && pageNo < pageCount) {
      setPageIndex(pageNo);
    } else {
    }
  };
  const nextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
    }
  };
  const previousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
    }
  };
  // Calculate the start index and end index for the current page
  const startIndex = pageIndex * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // get rsvps to display on the current page
  const pageRsvps = filteredRsvps.slice(startIndex, endIndex);

  if (!filteredRsvps || !selectedEventId || !events) {
    return <>No RSVPs or events or selectedEvent</>;
  }

  // get selected event obj
  const event = events.find((e) => e.eventId === selectedEventId);

  const handleSubmitRsvpComment = async (
    rsvpEventId,
    content,
    isReply,
    replyCommentId
  ) => {
    try {
      if (!employee) {
        toast.error(`An error occurred: employee not logged in`);
        return;
      }
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/submitEventRsvpComment`,
        {
          rsvpEventId,
          employee,
          content,
          isReply,
          replyCommentId,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh rsvps
        fetchRsvps();
        return true;
      } else if (info) {
        toast(`${info}`);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleUpdateRsvpOptionsRequest = async (status, final) => {
    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        rsvp: newRsvp,
      } = await api.post(`landmark/events/updateEventRsvpOptionRequest`, {
        rsvpEventId: selectedRsvpEvent.rsvpEventId,
        optionRequestId: selectedOptionRequest.optionRequestId,
        status,
        final,
      });
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update the rsvp
        const updatedRsvps = rsvps.map((r) =>
          r.rsvpEventId === newRsvp.rsvpEventId ? newRsvp : r
        );
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleUpdateRsvpCreateOptionRequest = async (status) => {
    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        optionRequest,
        rsvp: newRsvp,
      } = await api.post(`landmark/events/createEventRsvpOptionRequest`, {
        rsvpEventId: selectedRsvpEvent.rsvpEventId,
        optionId: notRequestedOptionId,
        status,
      });
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        setNotRequestedOptionId(null);
        setSelectedOptionRequest(optionRequest);
        // update the rsvp
        const updatedRsvps = rsvps.map((r) =>
          r.rsvpEventId === newRsvp.rsvpEventId ? newRsvp : r
        );
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleUpdateRsvpAttendance = async (attendanceStatus) => {
    try {
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/updateEventRsvpAttendanceStatus`,
        {
          rsvpEventId: selectedRsvpEvent.rsvpEventId,
          attendanceStatus,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh rsvps
        fetchRsvps();
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendConfirmationEmail = async (rsvpEventId) => {
    try {
      if (!employee) {
        toast.error(`An error occurred: employee not logged in`);
        return;
      }
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/sendRsvpConfirmationEmail`,
        {
          rsvpEventId,
          employee,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh confirm emails
        fetchConfirmEmails();
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendDeniedEmail = async (rsvpEventId) => {
    try {
      if (!employee) {
        toast.error(`An error occurred: employee not logged in`);
        return;
      }
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/sendRsvpDeniedEmail`,
        {
          rsvpEventId,
          employee,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // refresh denied emails
        fetchDeniedEmails();
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const selectRsvps = (rsvpsToSelect) => {
    const newSelectedRsvpIds = [...selectedRsvpIds];
    rsvpsToSelect.forEach((rsvp) => {
      if (!newSelectedRsvpIds.includes(rsvp.rsvpEventId)) {
        newSelectedRsvpIds.push(rsvp.rsvpEventId);
      }
    });
    setSelectedRsvpIds(newSelectedRsvpIds);
  };

  const unselectRsvps = (rsvpsToUnselect) => {
    const rsvpIdsToUnselect = rsvpsToUnselect.map((r) => r.rsvpEventId);
    setSelectedRsvpIds((prevSelectedRsvpIds) => {
      return prevSelectedRsvpIds.filter((r) => !rsvpIdsToUnselect.includes(r));
    });
  };

  const toggleSelectAllPageRsvps = (event) => {
    console.log(`toggleSelectAllPageRsvps() - event.target`, event.target);
    const checked = event.target.checked;
    // select all rsvps on this page
    if (checked) {
      selectRsvps(pageRsvps);
    }
    // unselect all rsvps on this page
    else {
      unselectRsvps(pageRsvps);
    }
  };
  const toggleSelectRsvp = (event, rsvp) => {
    const checked = event.target.checked;
    console.log(`toggleSelectRsvp() - checked`, checked);
    if (checked) {
      selectRsvps([rsvp]);
    } else {
      unselectRsvps([rsvp]);
    }
  };

  if (!selectedEvent) {
    return null;
  }
  console.log(`selectedEvent`, selectedEvent);

  const handleSetFilter = (filterKey, value) => {
    const newFilters = { ...filters };
    newFilters[filterKey] = value;
    setFilters(newFilters);
  };

  let headers = [];
  // Assuming rsvps is not an empty array
  if (rsvps.length > 0) {
    const headersSet = new Set();
    rsvps.forEach((r) => {
      Object.keys(r).forEach((key) => {
        headersSet.add(key);
      });
    });

    headers = Array.from(headersSet).map((h) => {
      return { key: h, name: h };
    });
    // add option headers
    if (selectedEvent && selectedEvent.options) {
      selectedEvent.options
        .sort((a, b) => a.startTime - b.startTime)
        .forEach((option) => {
          const { title, optionId } = option;
          headers.push({ key: optionId, name: title });
        });
    }
    // remove unwanted fields
    const unwanted = [
      "attendanceStatusUpdates",
      "comments",
      "confirmEmails",
      "optionRequests",
      "__v",
      "_id",
      "partnerResponses",
    ];
    headers = headers.filter((h) => !unwanted.includes(h.key));
    console.log(`headers`, headers);
  }

  const prepRsvpsForCsvExport = () => {
    return rsvps
      .filter((r) => r.eventId === selectedEvent.eventId)
      .map((r) => {
        const rsvp = JSON.parse(JSON.stringify(r));
        rsvp.optionRequests.forEach((or) => {
          const { optionId, status } = or;
          rsvp[optionId] = status;
        });
        rsvp.createdTime = epochToDateTimeString(rsvp.createdTime);
        // selectedEvent.options.forEach((option) => {
        //   const { optionId } = option;
        //   if (!rsvp[optionId]) {
        //     rsvp[optionId] = "not requested";
        //   }
        // });
        // delete rsvp.attendanceStatusUpdates;
        // delete rsvp.comments;
        // delete rsvp.confirmEmails;
        // delete rsvp.optionRequests;
        // delete rsvp.__v;
        // delete rsvp._id;
        return rsvp;
      });
  };

  const nonPendingStatusesExistForSelectedRsvpsBulkOnlyAffectOptionId = () => {
    if (!bulkOnlyAffectOptionId) {
      // not filtering by optionId so don't need to check
      return;
    } else {
      const selectedRsvps = rsvps.filter((r) =>
        selectedRsvpIds.includes(r.rsvpEventId)
      );
      console.log(
        `checkForNonPendingStatusesBulkOnlyAffectOptionId() - selectedRsvps.length`,
        selectedRsvps.length
      );
      for (let i = 0; i < selectedRsvps.length; i++) {
        const rsvp = selectedRsvps[i];
        if (rsvp.optionRequests) {
          const relevantOrs = rsvp.optionRequests.filter(
            (or) => or.optionId === bulkOnlyAffectOptionId
          );
          if (relevantOrs.some((or) => or.status.toLowerCase() !== "pending")) {
            // non-pending status found - show warning
            return true;
          }
        }
      }
    }
    return false;
  };

  const setSelectedRsvpsAllOptionRequestsStatuses = async (status) => {
    if (nonPendingStatusesExistForSelectedRsvpsBulkOnlyAffectOptionId()) {
      window.alert(
        "Some of the affected requests are already Approved/Waitlisted/Denied. This is a safeguard to prevent accidentally overwriting those requests. Please use the filters to filter the event to just Pending requests only. No action has been taken. (If this is annoying you then tell mjones and he will remove this safeguard)"
      );
      return;
    }

    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        rsvps: newRsvps,
      } = await api.post(
        `landmark/events/setStatusAllEventRsvpOptionRequestBatch`,
        {
          rsvpEventIds: selectedRsvpIds,
          status,
          bulkOnlyAffectOptionId,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update the rsvps
        const updatedRsvps = rsvps.map((rsvp) => {
          // find if there's a corresponding newRsvp with the same rsvpEventId
          const matchingNewRsvp = newRsvps.find(
            (newRsvp) => newRsvp.rsvpEventId === rsvp.rsvpEventId
          );
          // if there's a matching newRsvp, replace the old one, otherwise keep the old one
          return matchingNewRsvp ? matchingNewRsvp : rsvp;
        });
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const setSelectedRsvpsAllOptionRequestsAsFinal = async (final = true) => {
    try {
      const api = new ApiService({ password, employee });
      const {
        success,
        error,
        info,
        rsvps: newRsvps,
      } = await api.post(
        `landmark/events/setFinalAllEventRsvpOptionRequestBatch`,
        {
          rsvpEventIds: selectedRsvpIds,
          final,
          bulkOnlyAffectOptionId,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update the rsvps
        const updatedRsvps = rsvps.map((rsvp) => {
          // find if there's a corresponding newRsvp with the same rsvpEventId
          const matchingNewRsvp = newRsvps.find(
            (newRsvp) => newRsvp.rsvpEventId === rsvp.rsvpEventId
          );
          // if there's a matching newRsvp, replace the old one, otherwise keep the old one
          return matchingNewRsvp ? matchingNewRsvp : rsvp;
        });
        setRsvps(updatedRsvps);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteSelectedRsvps = async () => {
    // Check if any RSVPs are selected
    if (selectedRsvpIds.length === 0) {
      toast.error("No RSVPs selected for deletion");
      return;
    }

    // Show confirmation prompt
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedRsvpIds.length} RSVP${
        selectedRsvpIds.length === 1 ? "" : "s"
      }?`
    );

    if (!confirmDelete) {
      // User clicked "Cancel"
      return;
    }

    try {
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/deleteRsvps`,
        {
          rsvpEventIds: selectedRsvpIds,
          deleted: true,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // Refresh RSVPs
        fetchRsvps();
        setSelectedRsvpIds([]); // clear selected since we deleted them all
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const undeleteSelectedRsvps = async () => {
    // Check if any RSVPs are selected
    if (selectedRsvpIds.length === 0) {
      toast.error("No RSVPs selected for restoration");
      return;
    }

    try {
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/events/deleteRsvps`,
        {
          rsvpEventIds: selectedRsvpIds,
          deleted: false,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // Refresh RSVPs
        fetchRsvps();
        // setSelectedRsvpIds([]); // clear selected since we deleted them all
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const csvAdjustedRsvps = prepRsvpsForCsvExport();
  const filteredCsvAdjustedRsvps = csvAdjustedRsvps.filter((r) =>
    filteredRsvps.some((fr) => fr.rsvpEventId === r.rsvpEventId)
  );
  const selectedCsvAdjustedRsvps = csvAdjustedRsvps.filter((r) =>
    selectedRsvpIds.some((id) => id === r.rsvpEventId)
  );
  const csvAdjustedRsvpsNoDeleted = csvAdjustedRsvps.filter((r) => !r.deleted);

  // // for approved batches
  // Step 1: Filter RSVPs that have at least one approved and final option request
  // const csvRsvpsAllApprovedFinal = csvAdjustedRsvpsNoDeleted.filter((rsvp) => {
  //   const approved = rsvp.optionRequests.some(
  //     (or) => or.status === StatusEnum.APPROVED && or.final
  //   );
  //   return approved;
  // });

  const selectedRsvpsIncludeDeleted =
    selectedRsvpIds &&
    selectedRsvpIds.length &&
    rsvps
      .filter((r) => r.deleted)
      .some((r) => selectedRsvpIds.includes(r.rsvpEventId));

  return (
    <>
      {rsvps && rsvps.length ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="event-image-img">
              <img src={selectedEvent.imageUrl} className="img-fluid" />
            </div>
            <div className="row">
              <div className="outputHeader">{selectedEvent.title} RSVPs</div>
              <div className="events-owners-subheading">
                Browse and export RSVPs received for {selectedEvent.title}
                {/* Recommended workflow:
                <ul>
                <li>• Filter the data based on event (or company, etc.)</li>
                <li>
                • Click 'Export Current' to export the filtered data to a
                spreadsheet
                </li>
                <li>• Once handled, click 'Mark Current as Handled'</li>
              </ul> */}
              </div>
              <div className="col-12">
                <div className="add-edit-btn-container"></div>
              </div>
              <>
                <></>
                <div className="outputBody">
                  <div className="table-wrapper">
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div
                          className={`table-wrapper ${
                            filtersEnabled ? "" : "filters-disabled"
                          }`}
                        >
                          {/* <div className="events-owners-subheading">
                            Filters
                          </div> */}
                          <div className="filters-enable-row">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="filterBypassSwitch"
                                checked={filtersEnabled}
                                onChange={(e) => handleSetFiltersEnabled(e)}
                              ></input>
                              <label
                                className="form-check-label enable-filters-label"
                                for="filterBypassSwitch"
                              >
                                Enable Filters
                              </label>
                            </div>
                          </div>
                          <div className="filters-row">
                            <FilterInput
                              filterKey="fullName"
                              placeholder="Name"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="email"
                              placeholder="Email"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="jobTitle"
                              placeholder="Job Title"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="company"
                              placeholder="Company"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="city"
                              placeholder="City"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="country"
                              placeholder="Country"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="referredBy"
                              placeholder="Referred By"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            {selectedEvent.options
                              .sort((a, b) => a.startTime - b.startTime)
                              .map((option) => {
                                const { title, optionId } = option;
                                return (
                                  <FilterOption
                                    title={title}
                                    filterKey={optionId}
                                    // optionsEnum={StatusEnum}
                                    filters={filters}
                                    handleSetFilter={handleSetFilter}
                                  />
                                );
                              })}
                            <FilterOption
                              title="Attending"
                              filterKey="attendanceStatus"
                              //   optionsEnum={AttendanceStatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                          </div>
                          <br />
                          <div className="filters-row">
                            {/* <FilterOption
                              title="All Requested Events"
                              filterKey="allRequested"
                            //   optionsEnum={StatusEnum}
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />

                            <FilterCheckbox
                              title="Approved (for at least one):"
                              checked={approvedFilter}
                              handleChecked={handleSetApprovedFilter}
                            /> */}

                            {/* <FilterCheckbox
                              title="Confirmation Email Sent:"
                              checked={confirmEmailSentFilter}
                              handleChecked={handleSetConfirmEmailSentFilter}
                            />
                            <FilterCheckbox
                              title="Denied Email Sent:"
                              checked={deniedEmailSentFilter}
                              handleChecked={handleSetDeniedEmailSentFilter}
                            /> */}
                          </div>
                          <div className="filters-row">
                            {/* <FilterCheckbox
                              title="Duplicate Emails Only:"
                              checked={duplicatesOnlyFilter}
                              handleChecked={handleSetDuplicatesOnlyFilter}
                            /> */}
                          </div>
                        </div>
                      </motion.div>
                    }
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div className="table-wrapper">
                          <div
                            className="events-owners-subheading"
                            style={{ marginBottom: "8px" }}
                          >
                            {`Totals${filtersEnabled ? ` (filtered)` : ``}`}
                          </div>
                          <div className="totals-row rmono">
                            <div className="total-col">
                              <div className="total-line">
                                <div className="field">RSVPs: </div>
                                <div className="value">
                                  {filteredRsvps.length}
                                </div>
                              </div>
                            </div>
                            {selectedEvent.options
                              .sort((a, b) => a.startTime - b.startTime)
                              .map((option) => {
                                const { title, optionId } = option;

                                return (
                                  <div className="total-col">
                                    <div className="total-line option">
                                      <div className="field">{title}: </div>
                                      <div className="value">
                                        {
                                          filteredRsvps.filter((r) =>
                                            r.optionRequests.some(
                                              (or) => or.optionId === optionId
                                            )
                                          ).length
                                        }
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            <div className="total-col">
                              <div className="total-line option">
                                <div className="field">Total Applied: </div>
                                <div className="value">
                                  {filteredRsvps.reduce(
                                    (sum, fr) => sum + fr.optionRequests.length,
                                    0
                                  )}
                                </div>
                              </div>
                              {/* <div className={`total-line col-approved`}>
                                <div className="field">{"Approved "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) =>
                                          or.status === StatusEnum.APPROVED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div> */}
                              {/* <div className={`total-line col-waitlisted`}>
                                <div className="field">{"Waitlisted "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) =>
                                          or.status === StatusEnum.WAITLISTED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-denied`}>
                                <div className="field">{"Denied "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) => or.status === StatusEnum.DENIED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-pending`}>
                                <div className="field">{"Pending "}</div>
                                <div className={`value`}>
                                  {filteredRsvps.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) => or.status === StatusEnum.PENDING
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div> */}
                              {/* <div className="total-line option">
                                <div className="field">Attending: </div>
                                <div className="value">
                                  {
                                    filteredRsvps.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.ATTENDING
                                    ).length
                                  }
                                </div>
                              </div>
                              <div className={`total-line col-tentative`}>
                                <div className="field">{"Tentative "}</div>
                                <div className={`value`}>
                                  {
                                    filteredRsvps.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.TENTATIVE
                                    ).length
                                  }
                                </div>
                              </div>
                              <div className={`total-line col-not-attending`}>
                                <div className="field">{"No "}</div>
                                <div className={`value`}>
                                  {
                                    filteredRsvps.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.NOT_ATTENDING
                                    ).length
                                  }
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    }
                    {selectedRsvpIds && selectedRsvpIds.length ? (
                      <div className="selected-rsvps-controls-container">
                        <div className="selected-display">{`${
                          selectedRsvpIds.length
                        } RSVP${
                          selectedRsvpIds.length === 1 ? "" : "s"
                        } selected`}</div>
                        <div className="text-center mb-4">
                          <div className="mt-3">
                            <div className="filters-row">
                              <FilterOption
                                title="Only affect requests for event"
                                filterKey="bulkRequestEvent"
                                noTitleCase={true}
                                optionsEnum={selectedEvent.options.reduce(
                                  (acc, o) => {
                                    acc[o.optionId] = o.title;
                                    return acc;
                                  },
                                  {}
                                )}
                                filters={filters}
                                handleSetFilter={handleSetFilter}
                              />
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={
                                () => null
                                // setSelectedRsvpsAllOptionRequestsStatuses(
                                //   StatusEnum.APPROVED
                                // )
                              }
                              // style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as `}
                              <div class="badge-wrapper inline">
                                <span class="status-badge hvr-shrink badge bg-success">
                                  Approved
                                </span>
                              </div>
                              {/* {`(${selectedRsvpIds.length} RSVPs)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() => null}
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as `}
                              <div class="badge-wrapper inline">
                                <span class="status-badge hvr-shrink badge bg-warning">
                                  Waitlisted
                                </span>
                              </div>
                              {/* {`(${selectedRsvpIds.length} RSVPs)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>

                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedRsvpsAllOptionRequestsAsFinal(true)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all requests ${
                                bulkOnlyAffectOptionId ? "for event " : ""
                              }as Final`}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mb-3">
                          <div className="mt-3">
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn unselect-btn`}
                              onClick={() => setSelectedRsvpIds([])}
                              // style={{ marginLeft: "10px" }}
                            >
                              Unselect All
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-danger email-options-btn delete-rsvps-btn`}
                              onClick={() => deleteSelectedRsvps()}
                              style={{ marginLeft: "10px" }}
                            >
                              {`Delete RSVP${
                                selectedRsvpIds.length === 1 ? "" : "s"
                              }`}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            {selectedRsvpsIncludeDeleted ? (
                              <div
                                className={`create-event-btn btn btn-sm btn-danger email-options-btn undelete-rsvps-btn`}
                                onClick={() => undeleteSelectedRsvps()}
                                style={{ marginLeft: "10px" }}
                              >
                                {`Restore Deleted RSVP${
                                  selectedRsvpIds.length === 1 ? "" : "s"
                                }`}
                                {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <Table responsive className="header-border ">
                      <thead>
                        <tr>
                          <th className="width50 ">
                            <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkAll"
                                required
                                defaultChecked={false}
                                checked={
                                  !pageRsvps.some(
                                    (pr) =>
                                      !selectedRsvpIds.includes(pr.rsvpEventId)
                                  )
                                }
                                onChange={toggleSelectAllPageRsvps}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkAll"
                              ></label>
                            </div>
                          </th>
                          <th>User</th>
                          {event.options
                            .sort((a, b) => a.startTime - b.startTime)
                            .map((option) => {
                              return <th>{option.title}</th>;
                            })}
                          <th>Attending</th>
                          <th>Confirmation Email</th>
                          <th>Denied Email</th>
                          <th>Partner Options</th>
                          <th>Business Goals</th>
                          <th>Referred By</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pageRsvps.map((rsvp, i) => {
                          const {
                            rsvpEventId,
                            attendanceStatus,
                            attendanceStatusUpdates,
                            company,
                            createdTime,
                            email,
                            eventId,
                            eventTitle,
                            firstName,
                            lastName,
                            jobTitle,
                            businessGoals,
                            referredBy,
                            city,
                            country,
                            optionRequests,
                            comments,
                            partnerEventSponsorship,
                            partnerCustomEvent,
                            partnerIndustryTrendsBriefing,
                            deleted,
                          } = rsvp;

                          return (
                            <tr
                              key={`tr_row_${i}`}
                              className={`${deleted ? "deleted" : ""}`}
                            >
                              <td>
                                <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox2"
                                    required
                                    defaultChecked={false}
                                    checked={selectedRsvpIds.includes(
                                      rsvp.rsvpEventId
                                    )}
                                    onChange={(event) =>
                                      toggleSelectRsvp(event, rsvp)
                                    }
                                  />
                                </div>
                              </td>
                              <td
                                className="wrap"
                                style={{
                                  maxWidth: "550px",
                                  minWidth: "550px",
                                  width: "550px",
                                }}
                              >
                                <div className="user-container">
                                  {deleted ? (
                                    <div className="deleted">DELETED</div>
                                  ) : null}

                                  <div className="name">
                                    {firstName} {lastName}
                                  </div>
                                  <div className="email">
                                    {email}{" "}
                                    <span
                                      className="clipboard-icon hvr-shrink"
                                      onClick={() => copyText(email, "email")}
                                    >
                                      <FileCopyOutlinedIcon
                                        style={{
                                          width: "0.7em",
                                          height: "0.7em",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className="job">
                                    {jobTitle} at {company}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="badge-wrapper">
                                  {/* <Badge
                                    bg={null}
                                    className={`status-badge hvr-shrink ${getBadgeClassFromAttendanceStatus(
                                      attendanceStatus
                                    )}`}
                                    onClick={(event) => {
                                      handleBadgeClickAttendance(event, rsvp);
                                    }}
                                  >
                                    {getAttendanceStatusDisplayText(
                                      attendanceStatus
                                    )}
                                  </Badge> */}
                                </div>
                              </td>
                              {/* confirmation email cell */}

                              <td></td>

                              {/* <td
                                className="wrap"
                                style={{ maxWidth: "130px", minWidth: "130px" }}
                              >
                                {country}
                              </td> */}
                              <td></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-between">
                      <span className="table-index">
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageCount}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Rows per page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={rowsPerPage}
                          min={1}
                          max={500}
                          onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                          }}
                        />
                      </span>
                      <span className="table-index">
                        Go to page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={pageIndex + 1}
                          min={1}
                          max={pageCount}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            goToPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => goToPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => goToPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <div className="mt-3">
                        {/* <button
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          onClick={handleExportData}
                        >
                          {`Export All RSVPs`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </button> */}
                        <ExportJsonCsv
                          headers={headers}
                          items={csvAdjustedRsvpsNoDeleted}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                        >
                          {`Export All RSVP${
                            csvAdjustedRsvpsNoDeleted.length === 1 ? "" : "s"
                          } (${csvAdjustedRsvpsNoDeleted.length})`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv>
                        {filtersEnabled ? (
                          <ExportJsonCsv
                            headers={headers}
                            items={filteredCsvAdjustedRsvps}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                            style={{ marginLeft: "10px" }}
                          >
                            {`Export Filtered RSVP${
                              filteredCsvAdjustedRsvps.length === 1 ? "" : "s"
                            } (${filteredCsvAdjustedRsvps.length})`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv>
                        ) : null}
                        {selectedRsvpIds && selectedRsvpIds.length ? (
                          <ExportJsonCsv
                            headers={headers}
                            items={selectedCsvAdjustedRsvps}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                            style={{ marginLeft: "10px" }}
                          >
                            {`Export Selected RSVP${
                              selectedCsvAdjustedRsvps.length === 1 ? "" : "s"
                            } (${selectedCsvAdjustedRsvps.length})`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv>
                        ) : null}
                        {/* <ExportJsonCsv
                          headers={headers}
                          items={csvRsvpsAllApprovedFinal.filter(
                            (r) => r.tempConfirmEmailHtml
                          )}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          style={{ marginLeft: "10px" }}
                        >
                          {`Export All Approved Final RSVPs ready to send a conf email`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              {!rsvps ? (
                <div className="text-center">
                  <img
                    className="m-auto mt-3 mb-3"
                    width={74}
                    src={loadingCircleImg}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </motion.div>
      ) : (
        <div className="no-rsvps-msg">Loading RSVPs</div>
      )}
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const SelectEvent = ({ password, selectedEvent, setSelectedEvent }) => {
  const [events, setEvents] = useState([]);

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`OwnerAssign - events`, eventsResponse);
      setEvents(eventsResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Events</div>
            <>
              <div className="events-owners-subheading">
                Select Event to view RSVPs
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {events.map((event) => {
                  const selectedClass =
                    selectedEvent && event.eventId === selectedEvent.eventId
                      ? "selected"
                      : "";
                  return (
                    <div key={event.eventId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedEvent(event)}
                      >
                        <img
                          src={event.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{event.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};
TablePaginationActions2.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions2(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees
              .sort((a, b) => {
                // If both have 'lastSeenTime', compare by 'lastSeenTime' (descending)
                if (a.lastSeenTime && b.lastSeenTime) {
                  return b.lastSeenTime - a.lastSeenTime;
                }

                // If one has 'lastSeenTime', prioritize that one
                if (a.lastSeenTime && !b.lastSeenTime) {
                  return -1;
                }
                if (!a.lastSeenTime && b.lastSeenTime) {
                  return 1;
                }

                // If neither have 'lastSeenTime', compare by 'firstName' alphabetically
                return a.firstName.localeCompare(b.firstName);
              })
              .map((employee, i) => (
                <EmployeeInfo
                  key={i}
                  landmarkEmployee={employee}
                  handleOnClick={signInAsEmployee}
                />
              ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [optilists, setOptilists] = useState([]);
  const [selectedOptilist, setSelectedOptilist] = useState(null);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function getOptilists() {
    try {
      const api = new ApiService({ password });
      const response = await api.get("landmark/optilist/getOptilists");
      setOptilists(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getOptilists();
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  const signInAsEmployee = (employee) => {
    if (!employee) {
      toast.error("Cannot sign in as employee");
      return;
    }
    setIsSignedIn(true);
    setEmployee(employee);

    try {
      const api = new ApiService({ employee });
      // sets lastSeenTime
      api.get(`landmark/employees/login`);
    } catch (error) {
      console.error(`signInAsEmployee() - employee login error`, error);
    }

    toast.success(`Logged in as ${employee.firstName} ${employee.lastName}`);
  };
  useDocumentTitle("OptiList Dashboard - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        {isSignedIn && (
          <div className="landmark-sign-in" style={{ marginBottom: "18px" }}>
            <div className="existing-accounts-container">
              <EmployeeInfo landmarkEmployee={employee} disableHover={true} />
            </div>
          </div>
        )}
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "OptiList Dashboard", url: `/landmark/OptiList` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated && isSignedIn ? (
          <>
            {/* const AddEditOptilist = ({
  password,
  optilists,
  setOptilists,
  selectedOptilist,
  setSelectedOptilist,
  getOptilists,
}) => { */}
            <AddEditOptilist
              password={password}
              employee={employee}
              getOptilists={getOptilists}
              optilists={optilists}
              setOptilists={setOptilists}
              selectedOptilist={selectedOptilist}
              setSelectedOptilist={setSelectedOptilist}
            />
            {/* <OwnerAssign password={password} employee={employee} /> */}
            {/* <SelectEvent
              password={password}
              employee={employee}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            /> */}
            {/* {selectedEvent && (
              <MasterEventsTable
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                password={password}
                employee={employee}
              />
            )} */}
          </>
        ) : null}
        {/* show sign in */}
        {isAuthenticated && !isSignedIn ? (
          <>
            <motion.div
              animate={{
                scale: [0, 1.04, 1],
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <EmployeeSignIn
                password={password}
                signInAsEmployee={signInAsEmployee}
              />
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
